import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import { SearchRow } from '../../components/SoundBrowser/SearchRow';
import BatchUploadTable from '../../pages/BatchUpload/BatchUploadTable';
import { 
    fetchSounds, 
    updateSound,
    fetchSoundTypes, 
    fetchTags, 
    fetchGenres, 
    fetchInstruments, 
    fetchLicenses ,
    fetchSoundpacks,
    createSoundpack,
    createGenre,
    createInstrument,
    createTag
} from '../../api/APIManager';
import Spinner from '../../components/Spinner';
import { useToast } from '../../context/ToastContext';

const BatchEdit = () => {
    const { showToast } = useToast();
    const [sounds, setSounds] = useState([]);
    const [soundTypes, setSoundTypes] = useState([]);
    const [availableTags, setAvailableTags] = useState([]);
    const [availableGenres, setAvailableGenres] = useState([]);
    const [availableInstruments, setAvailableInstruments] = useState([]);
    const [licenses, setLicenses] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [currentFilters, setCurrentFilters] = useState({});
    const { setCurrentPlayingSound, setIsPlaying, currentPlayingSound, isPlaying } = useOutletContext();
    const uploadCancelRef = useRef(false);
    const [isUploading, setIsUploading] = useState(false);
    const pageSize = 100;
    const [soundpacks, setSoundpacks] = useState([]);

    const [searchRowFilters, setSearchRowFilters] = useState({
        key: [],
        instruments: [],
        genres: [],
        tags: [],
        searchText: '',
        sortBy: [{ id: 'popular', name: 'Most Popular' }],
        sort_by_likes: true
    });

    const transformApiSound = (sound) => ({
        id: sound.id,
        file: null,
        name: sound.name,
        preview: sound.audio_preview,
        featured: sound.featured || false, 
        description: sound.description || '',
        tags: sound.tags || [],
        genres: sound.genres || [],
        instruments: sound.instruments || [],
        typeId: sound.type?.id || '',
        bpm: sound.bpm?.toString() || '',
        key: sound.key || '',
        status: sound.status || 'active',
        costInCredits: sound.cost_in_credits?.toString() || '1',
        license: sound.license?.id || '',
        sound_packs: sound.sound_packs || null, 
        image: null,
        imagePreview: sound.image || null,
        uploadStatus: 'pending'
    });

    const loadSounds = useCallback(async (newPage, filters = {sort_by_likes: true, status: "all"}) => {
        try {
            setIsLoading(true);
            const response = await fetchSounds(newPage, pageSize, filters);
            const transformedSounds = response.items.map(transformApiSound);
            setSounds(transformedSounds);
            setTotalItems(response.total);
            setTotalPages(Math.ceil(response.total / pageSize));
            setPage(newPage);
        } catch (error) {
            console.error("Error loading sounds:", error);
            showToast("Failed to load sounds. Please try again.", "error");
        } finally {
            setIsLoading(false);
        }
    }, [showToast, pageSize]);

    useEffect(() => {
        const loadInitialData = async () => {
            try {
                const [
                    types,
                    fetchedTags,
                    fetchedGenres,
                    fetchedInstruments,
                    fetchedLicenses,
                    fetchedSoundpacks,  // Add this line
                ] = await Promise.all([
                    fetchSoundTypes(),
                    fetchTags(),
                    fetchGenres(),
                    fetchInstruments(),
                    fetchLicenses(),
                    fetchSoundpacks({page_size: 200}),  // Add this line
                ]);

                setSoundTypes(types);
                setAvailableTags(fetchedTags);
                setAvailableGenres(fetchedGenres);
                setAvailableInstruments(fetchedInstruments);
                setLicenses(fetchedLicenses);
                setSoundpacks(fetchedSoundpacks.items); 
                
                await loadSounds(1);
                
            } catch (error) {
                console.error("Error loading initial data:", error);
                showToast("Failed to load initial data. Please try again.", "error");
            } finally {
                setIsLoading(false);
            }
        };

        loadInitialData();
    }, [loadSounds, showToast]);

    const handleSearch = async (newFilters) => {
        setIsLoading(true);
        setSearchRowFilters(newFilters);

        const apiFilters = {
            query: newFilters.searchText || "",
            genre_ids: newFilters.genres?.map(genre => genre.id).join(','),
            tag_ids: newFilters.tags?.map(tag => tag.id).join(','),
            instrument_ids: newFilters.instruments?.map(instrument => instrument.id).join(','),
            key: newFilters.key?.[0]?.name,
            sort_by_likes: newFilters.sortBy?.[0]?.id === 'popular',
            status: "all"
        };

        setCurrentFilters(apiFilters);
        await loadSounds(1, apiFilters);
        setIsLoading(false);
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            loadSounds(newPage, currentFilters);
        }
    };

    const handleDirectPageInput = (e) => {
        if (e.key === 'Enter') {
            const pageNumber = parseInt(e.target.value);
            if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
                handlePageChange(pageNumber);
            } else {
                showToast(`Please enter a page number between 1 and ${totalPages}`, "error");
            }
        }
    };

    const getPageNumbers = () => {
        const pageNumbers = [];
        const maxVisiblePages = 5; // Number of page buttons to show
        
        // Always show first page
        pageNumbers.push(1);
        
        let startPage = Math.max(2, page - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages - 1, startPage + maxVisiblePages - 1);
        
        // Adjust if we're near the end
        if (endPage - startPage < maxVisiblePages - 1) {
            startPage = Math.max(2, endPage - maxVisiblePages + 2);
        }
        
        // Add ellipsis after first page if needed
        if (startPage > 2) {
            pageNumbers.push('...');
        }
        
        // Add middle pages
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }
        
        // Add ellipsis before last page if needed
        if (endPage < totalPages - 1) {
            pageNumbers.push('...');
        }
        
        // Always show last page if there is more than one page
        if (totalPages > 1) {
            pageNumbers.push(totalPages);
        }
        
        return pageNumbers;
    };

    const editSound = async (soundData) => {
        try {
            console.log('Updating sound with license:', soundData.license);
    
            const updateData = {
                name: soundData.name,
                description: soundData.description,
                featured: soundData.featured, 
                bpm: parseInt(soundData.bpm) || null,
                key: soundData.key,
                cost_in_credits: parseInt(soundData.costInCredits) || 1,
                status: soundData.status,
                type_id: soundData.typeId,
                license: soundData.license,
                tags: soundData.tags,
                genres: soundData.genres,
                instruments: soundData.instruments,
                image: soundData.image,
                sound_packs: soundData.sound_packs,  // Add this line
            };
    
            console.log('Sending update data:', updateData);
    
            const updatedSound = await updateSound(soundData.id, updateData);
            
            console.log('Received updated sound:', updatedSound);
    
            setSounds(prevSounds => 
                prevSounds.map(sound => 
                    sound.id === soundData.id 
                        ? { 
                            ...transformApiSound(updatedSound), 
                            uploadStatus: 'success',
                            preview: sound.preview,
                            image: sound.image,
                            imagePreview: sound.imagePreview || updatedSound.image
                        }
                        : sound
                )
            );
    
            return {
                ...transformApiSound(updatedSound),
                uploadStatus: 'success'
            };
        } catch (error) {
            console.error('Failed to update sound:', error);
            setSounds(prevSounds => 
                prevSounds.map(sound => 
                    sound.id === soundData.id 
                        ? { ...sound, uploadStatus: 'error' }
                        : sound
                )
            );
            throw error;
        }
    };

    // Add handleCancelUpload function
    const handleCancelUpload = () => {
        uploadCancelRef.current = true;
        setIsUploading(false);
        setSounds(prevSounds => prevSounds.map(sound => {
            if (sound.uploadStatus === 'uploading' || sound.uploadStatus === 'pending') {
                return { ...sound, uploadStatus: 'cancelled' };
            }
            return sound;
        }));
    };

    return (
        <div className="flex flex-col h-screen overflow-hidden">
            <div className="flex-none px-4 sm:px-8 pt-4 sm:pt-8">
                <h1 className="text-3xl mb-4 font-bold">Batch Edit Sounds</h1>
                
                <div className="">
                    <SearchRow 
                        onSearch={handleSearch} 
                        initialFilters={{
                            ...searchRowFilters,
                            sort_by_likes: searchRowFilters.sortBy?.[0]?.id === 'popular'
                        }} 
                    />
                </div>
            </div>

            <div className="flex-1 min-h-0 px-4 sm:px-8 pb-4 overflow-hidden">
                {isLoading && page === 1 && <Spinner />}

                {sounds.length > 0 && (
                    <div className="h-full flex flex-col">
                        <div className="flex-1 min-h-0">
                            <BatchUploadTable
                                files={sounds}
                                setFiles={setSounds}
                                soundTypes={soundTypes}
                                availableTags={availableTags}
                                setAvailableTags={setAvailableTags}       // Add these
                                availableGenres={availableGenres}
                                setAvailableGenres={setAvailableGenres}   // Add these
                                availableInstruments={availableInstruments}
                                setAvailableInstruments={setAvailableInstruments} // Add these
                                licenses={licenses}
                                soundpacks={soundpacks} 
                                setSoundpacks={setSoundpacks}             // Add these
                                createTag={createTag}           // Add these
                                createGenre={createGenre}       // Add these
                                createInstrument={createInstrument} // Add these
                                createSoundpack={createSoundpack}   // Add this
                                setErrorMessage={(msg) => showToast(msg, "error")}
                                editMode={true}
                                setCurrentPlayingSound={setCurrentPlayingSound}
                                setIsPlaying={setIsPlaying}
                                currentPlayingSound={currentPlayingSound}
                                isPlaying={isPlaying}
                                uploadCancelRef={uploadCancelRef}
                                handleCancelUpload={handleCancelUpload}
                                handleUpload={editSound}
                            />
                        </div>

                        <div className="flex-none mt-4 flex flex-col sm:flex-row items-center justify-between gap-4 border-t border-text-primary/10 pt-4">
                            <div className="flex items-center gap-2 text-sm">
                                <span>Total: {totalItems} items</span>
                                <span className="text-text-primary/50">|</span>
                                <span>Page {page} of {totalPages}</span>
                            </div>
                            
                            <div className="flex items-center gap-2 flex-wrap justify-center">
                                <button
                                    onClick={() => handlePageChange(page - 1)}
                                    disabled={page === 1 || isLoading}
                                    className="px-3 py-1 rounded-lg border border-text-primary/15 disabled:opacity-50 hover:bg-accent-end/10"
                                >
                                    Previous
                                </button>
                                
                                <div className="flex items-center gap-1">
                                    {getPageNumbers().map((pageNum, index) => (
                                        <React.Fragment key={index}>
                                            {pageNum === '...' ? (
                                                <span className="px-2">...</span>
                                            ) : (
                                                <button
                                                    onClick={() => handlePageChange(pageNum)}
                                                    disabled={isLoading}
                                                    className={`px-3 py-1 rounded-lg border ${
                                                        pageNum === page
                                                            ? 'bg-accent-end text-text-primary border-accent-end'
                                                            : 'border-text-primary/15 hover:bg-accent-end/10'
                                                    }`}
                                                >
                                                    {pageNum}
                                                </button>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>

                                <button
                                    onClick={() => handlePageChange(page + 1)}
                                    disabled={page === totalPages || isLoading}
                                    className="px-3 py-1 rounded-lg border border-text-primary/15 disabled:opacity-50 hover:bg-accent-end/10"
                                >
                                    Next
                                </button>

                                <input
                                    type="number"
                                    min="1"
                                    max={totalPages}
                                    className="w-16 px-2 py-1 rounded-lg border border-text-primary/15 bg-transparent text-center"
                                    placeholder={page.toString()}
                                    onKeyDown={handleDirectPageInput}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BatchEdit;