import React, { useState, useEffect, useRef } from 'react';

export const PlayIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="white">
    <path d="M8 5v14l11-7z" />
  </svg>
);

export const PauseIcon = () => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill="white">
    <path d="M6 4h4v16H6zM14 4h4v16h-4z" />
  </svg>
);

export const LikeIcon = ({ filled }) => (
  <svg className="w-5 h-5" viewBox="0 0 24 24" fill={filled ? "currentColor" : "none"} stroke="currentColor" strokeWidth="2">
    <path d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
  </svg>
);

export const MoreIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
    <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
  </svg>
);

export const DownloadIcon = () => {
  const [strokeColor, setStrokeColor] = useState('#000');

  useEffect(() => {
    const tempElement = document.createElement('div');
    tempElement.className = 'text-text-primary hidden';
    document.body.appendChild(tempElement);
    const primaryColor = getComputedStyle(tempElement).color;
    setStrokeColor(primaryColor);
    document.body.removeChild(tempElement);
  }, []);

  return (
    <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke={strokeColor} strokeWidth="2">
      <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M7 10l5 5 5-5M12 15V3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const DragIcon = ({ onDragStart, onDragEnd }) => (
  <div
    className="drag-icon flex flex-col items-center justify-center cursor-grab active:cursor-grabbing w-full h-full"
    draggable="true"
    onDragStart={onDragStart}
    onDragEnd={onDragEnd}
  >
    <div className="flex flex-col items-center">
      <svg
        className="w-6 h-6"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M4 4C4 3.44772 4.44772 3 5 3H14.5858C14.851 3 15.1054 3.10536 15.2929 3.29289L19.7071 7.70711C19.8946 7.89464 20 8.149 20 8.41421V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V4Z" />
        <path d="M10 10.5V16.5" className="stroke-current" strokeWidth="1.5" />
        <path d="M12 9V18" className="stroke-current" strokeWidth="1.5" />
        <path d="M14 12V15" className="stroke-current" strokeWidth="1.5" />
        <path d="M14 3V8H19" className="stroke-current" strokeWidth="1.5" />
      </svg>
      <span className="text-[10px] mt-1 text-center text-text-secondary font-medium">
        Drag File
      </span>
    </div>
  </div>
);

export const CheckIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
  </svg>
);

 export const TokenBadge = () => (
    <svg className="w-5 h-5 inline-block ml-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" fill="#FFD700"/>
      <text x="12" y="16" textAnchor="middle" fill="#000" fontSize="12" fontWeight="bold">₮</text>
    </svg>
  );

 export const CashBadge = () => (
    <svg className="w-5 h-5 inline-block ml-1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" fill="#4CAF50"/>
      <text x="12" y="16" textAnchor="middle" fill="#fff" fontSize="14" fontWeight="bold">$</text>
    </svg>
  );


  export const VerifiedBadge = () => (
    <svg className="w-4 h-4 ml-1 inline-block" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* Original-sized star badge */}
      <path 
        d="M10 0L12.2 3.8L16.5 2.5L15.2 6.8L19 9L15.2 11.2L16.5 15.5L12.2 14.2L10 18L7.8 14.2L3.5 15.5L4.8 11.2L1 9L4.8 6.8L3.5 2.5L7.8 3.8L10 0Z"
        fill="#0095F6"
      />
      
      {/* Centered smaller checkmark */}
      <path 
        d="M14 7.2L9.85 11.35C9.55 11.65 9.08 11.65 8.78 11.35L7.02 9.59C6.72 9.29 6.72 8.82 7.02 8.52C7.32 8.22 7.79 8.22 8.09 8.52L9.31 9.74L12.93 6.12C13.23 5.82 13.7 5.82 14 6.12C14.3 6.42 14.3 6.9 14 7.2Z" 
        fill="white"
      />
    </svg>
  );