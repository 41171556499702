import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { CustomDialog } from '../CustomComponents';
import { StyledSelect } from '../CustomComponents';
import { useAudioNavigation } from '../../context/AudioNavigationContext';
import { JUCESend, JUCEReceive, isJUCE } from '../../context/JUCE';
import PropTypes from 'prop-types';

const WaveformDisplay = ({ audioUrl }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [audioBuffer, setAudioBuffer] = useState(null);
  const [containerWidth, setContainerWidth] = useState(0);
  
  const CANVAS_HEIGHT = 150;
  const PIXELS_PER_SECOND = 200;
  
  useEffect(() => {
    // Get initial container width
    if (containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth);
    }

    // Set up resize observer to update padding if container width changes
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        setContainerWidth(entry.contentRect.width);
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);
  
  useEffect(() => {
    const fetchAudio = async () => {
      try {
        const response = await fetch(audioUrl);
        const arrayBuffer = await response.arrayBuffer();
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const buffer = await audioContext.decodeAudioData(arrayBuffer);
        setAudioBuffer(buffer);
        
        const canvas = canvasRef.current;
        canvas.width = Math.ceil(buffer.duration * PIXELS_PER_SECOND);
        canvas.height = CANVAS_HEIGHT;
        
        drawWaveform(buffer);
      } catch (error) {
        console.error('Error loading audio:', error);
      }
    };
    
    fetchAudio();
  }, [audioUrl]);
  
  const drawWaveform = (buffer) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const data = buffer.getChannelData(0);
    const step = Math.ceil(data.length / canvas.width);
    const amp = CANVAS_HEIGHT / 2;
    
    ctx.fillStyle = '#232323';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    
    ctx.beginPath();
    ctx.moveTo(0, amp);
    
    ctx.strokeStyle = '#ffffff';
    ctx.lineWidth = 1;
    
    for (let i = 0; i < canvas.width; i++) {
      let min = 1.0;
      let max = -1.0;
      
      for (let j = 0; j < step; j++) {
        const datum = data[(i * step) + j];
        if (datum < min) min = datum;
        if (datum > max) max = datum;
      }
      
      ctx.lineTo(i, (1 + min) * amp);
      ctx.lineTo(i, (1 + max) * amp);
    }
    
    ctx.stroke();
  };
  
  const handleScroll = (e) => {
    if (!audioBuffer) return;
    
    const scrollPosition = e.target.scrollLeft;
    const timePosition = scrollPosition / PIXELS_PER_SECOND;
    const samplePosition = Math.floor(timePosition * audioBuffer.sampleRate);
    
    JUCESend('audioStartPointChanged', { value: samplePosition });
  };
  
  return (
    <div className="relative">
      <div 
        ref={containerRef}
        className="w-full h-40 overflow-x-auto bg-bg-secondary rounded-md"
        onScroll={handleScroll}
      >
        <div className="relative" style={{ 
          paddingLeft: `${Math.floor(containerWidth / 2)}px`, 
          paddingRight: `${Math.floor(containerWidth / 2)}px`,
          transform: 'translateX(8px)' // Micro-adjustment to align with center line
        }}>
          <canvas
            ref={canvasRef}
            height={CANVAS_HEIGHT}
          />
        </div>
      </div>
      {/* Static center line */}
      <div 
        className="absolute top-0 left-1/2 w-0.5 h-40 bg-accent-end pointer-events-none" 
        style={{ transform: 'translateX(-50%)' }}
      />
    </div>
  );
};

WaveformDisplay.propTypes = {
  audioUrl: PropTypes.string.isRequired
};

const AudioPlayer = ({ url, label, onDurationLoad, isStreaming = false }) => {
  const audioRef = useRef(null);
  const mediaSourceRef = useRef(null);

  useEffect(() => {
    if (isStreaming && audioRef.current) {
      if (typeof MediaSource !== 'undefined') {
        const mediaSource = new MediaSource();
        mediaSourceRef.current = mediaSource;
        audioRef.current.src = URL.createObjectURL(mediaSource);

        mediaSource.addEventListener('sourceopen', async () => {
          const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
          try {
            const response = await fetch(url);
            const reader = response.body.getReader();

            let done = false;
            while (!done) {
              const { value, done: streamDone } = await reader.read();
              if (value) {
                sourceBuffer.appendBuffer(value);
              }
              done = streamDone;
            }
            mediaSource.endOfStream();
          } catch (error) {
            console.error('Error streaming audio:', error);
            audioRef.current.src = url;
          }
        });
      } else {
        audioRef.current.src = url;
      }
    } else {
      audioRef.current.src = url;
    }
  }, [url, isStreaming]);

  return (
    <div className="mb-4">
      <p className="text-sm text-text-secondary mb-2">{label}</p>
      <audio
        ref={audioRef}
        controls
        className="w-full"
        onLoadedMetadata={(e) => {
          if (onDurationLoad) {
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            fetch(url)
              .then(response => response.arrayBuffer())
              .then(buffer => audioContext.decodeAudioData(buffer))
              .then(audioBuffer => {
                const exactDuration = audioBuffer.length / audioBuffer.sampleRate;
                onDurationLoad(exactDuration);
              });
          }
        }}
      >
        {!isStreaming && <source src={url} type="audio/mpeg" />}
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

AudioPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string,
  onDurationLoad: PropTypes.func,
  isStreaming: PropTypes.bool
};

const AISampleDialog = ({ isOpen, onClose, sound }) => {
  const { setCurrentPlayingSound, setIsPlaying } = useAudioNavigation();
  const [formData, setFormData] = useState({
    prompt: '',
    model: 'chirp-v4',
    styleNegative: '',
    continueAt: 10,
    style: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [generatedSamples, setGeneratedSamples] = useState(null);
  const [inProgressSamples, setInProgressSamples] = useState(null);
  const [error, setError] = useState('');
  const [taskId, setTaskId] = useState(null);
  const [pollInterval, setPollInterval] = useState(null);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const audioRef = useRef(null);

  const filename = sound.audio_preview.split('/').pop();
  const encodedFilename = encodeURIComponent(filename);
  const baseUrl = sound.audio_preview.substring(0, sound.audio_preview.lastIndexOf('/') + 1);
  const encodedUrl = baseUrl + encodedFilename;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDurationLoad = (duration) => {
    setFormData(prev => ({
      ...prev,
      continueAt: Number((duration / 2.0).toFixed(5))
    }));
  };

  const handlePlayPause = (sample) => {
    if (currentlyPlaying === sample.id) {
      audioRef.current.pause();
      setCurrentlyPlaying(null);
    } else {
      if (audioRef.current) {
        audioRef.current.src = sample.audio_url;
        audioRef.current.play();
        setCurrentlyPlaying(sample.id);
      }
    }
  };

  const handlePlayGeneratedSample = (sample) => {
    const modifiedSound = {
      ...sound,
      audio_preview: sample.audio_url,
      name: `${sound.name} (AI Generated)`,
      owner: sound.owner
    };
    setCurrentPlayingSound(modifiedSound);
    setIsPlaying(true);
    if (isJUCE()) {
      JUCESend('playSound', modifiedSound);
    }
  };

  const pollTaskStatus = async (taskId) => {
    try {
      const response = await axios.post('https://api.acedata.cloud/suno/tasks',
        {
          id: taskId,
          action: "retrieve"
        },
        {
          headers: {
            'Authorization': 'Bearer 641812e5c49949ebaad381cf2ac917f6',
            'Content-Type': 'application/json'
          }
        }
      );

      const samples = response.data.response.data;
      const allCompleted = samples.every(sample => sample.state === 'succeeded');

      if (allCompleted) {
        clearInterval(pollInterval);
        setPollInterval(null);
        setGeneratedSamples(samples);
        setInProgressSamples(null);
        setIsLoading(false);
      } else {
        setInProgressSamples(samples);
      }
    } catch (err) {
      console.error('Error polling task:', err);
    }
  };

  useEffect(() => {
    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
      }
    };
  }, [pollInterval]);

  const handleGenerate = async () => {
    setIsLoading(true);
    setError('');
    setGeneratedSamples(null);
    setInProgressSamples(null);
    
    try {
      const uploadResponse = await axios.post('https://api.acedata.cloud/suno/upload', 
        { audio_url: encodedUrl },
        { 
          headers: { 
            'Authorization': 'Bearer 641812e5c49949ebaad381cf2ac917f6',
            'Content-Type': 'application/json'
          }
        }
      );

      const { audio_id } = uploadResponse.data.data;

      const generateResponse = await axios.post('https://api.acedata.cloud/suno/audios',
        {
          action: "extend",
          prompt: formData.prompt,
          model: formData.model,
          custom: true,
          instrumental: true,
          style_negative: formData.styleNegative,
          audio_id: audio_id,
          continue_at: parseInt(formData.continueAt),
          style: formData.style,
          callback_url: "https://"
        },
        {
          headers: {
            'Authorization': 'Bearer 641812e5c49949ebaad381cf2ac917f6',
            'Content-Type': 'application/json'
          }
        }
      );

      const newTaskId = generateResponse.data.task_id;
      setTaskId(newTaskId);

      const interval = setInterval(() => pollTaskStatus(newTaskId), 10000);
      setPollInterval(interval);

    } catch (err) {
      setError(err.response?.data?.message || 'Failed to generate samples');
      setIsLoading(false);
    }
  };

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={() => {
        if (pollInterval) {
          clearInterval(pollInterval);
        }
        onClose();
      }}
      title="Generate AI Sample"
      maxWidth="md:max-w-2xl"
    >
      <div className="space-y-4">
        <AudioPlayer 
          url={encodedUrl} 
          label="Original Sound" 
          onDurationLoad={handleDurationLoad}
        />
        
        <div>
          <label className="block text-sm font-medium text-text-secondary mb-2">
            Style
          </label>
          <input
            type="text"
            name="style"
            value={formData.style}
            onChange={handleInputChange}
            className="w-full px-4 h-12 rounded-md bg-bg-primary border border-white/20 text-text-primary"
            placeholder="e.g. trap, melodic, 808"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-text-secondary mb-2">
            Style Negative
          </label>
          <input
            type="text"
            name="styleNegative"
            value={formData.styleNegative}
            onChange={handleInputChange}
            className="w-full px-4 h-12 rounded-md bg-bg-primary border border-white/20 text-text-primary"
            placeholder="Elements to avoid in generation..."
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-text-secondary mb-2">
            Continue At (seconds)
          </label>
          <input
            type="number"
            step="0.001"
            name="continueAt"
            value={formData.continueAt}
            onChange={handleInputChange}
            className="w-full px-4 h-12 rounded-md bg-bg-primary border border-white/20 text-text-primary"
            min="0"
          />
        </div>

        <button
          onClick={handleGenerate}
          disabled={isLoading}
          className="w-full h-12 bg-accent-end text-white rounded-md hover:bg-accent-end/80 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isLoading ? (
            <div className="flex items-center justify-center">
              <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
            </div>
          ) : (
            'Generate Samples'
          )}
        </button>

        {error && (
          <p className="text-red-500 text-sm">{error}</p>
        )}

        {inProgressSamples && (
          <div className="mt-6">
            <h3 className="text-lg font-medium text-text-primary mb-4">
              Samples Generating... (streaming preview)
            </h3>
            {inProgressSamples.map((sample, index) => (
              <AudioPlayer 
                key={sample.id}
                url={sample.audio_url}
                label={`Sample ${index + 1} (In Progress)`}
                isStreaming={true}
              />
            ))}
          </div>
        )}

        {/* Hidden audio element for playback control */}
        <audio ref={audioRef} className="hidden" />

        {generatedSamples && (
          <div className="mt-6">
            <h3 className="text-lg font-medium text-text-primary mb-4">Generated Samples</h3>
            {generatedSamples.map((sample, index) => (
              <div key={sample.id} className="mb-8">
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm text-text-secondary">Sample {index + 1}</span>
                  <div className="space-x-2">
                    <button
                      onClick={() => handlePlayPause(sample)}
                      className="px-4 py-2 bg-accent-start text-white rounded-md hover:bg-accent-start/80"
                    >
                      {currentlyPlaying === sample.id ? 'Pause' : 'Play'}
                    </button>
                    <button
                      onClick={() => handlePlayGeneratedSample(sample)}
                      className="px-4 py-2 bg-accent-end text-white rounded-md hover:bg-accent-end/80"
                    >
                      Use in Main Player
                    </button>
                  </div>
                </div>
                <WaveformDisplay audioUrl={sample.audio_url} />
              </div>
            ))}
          </div>
        )}
      </div>
    </CustomDialog>
  );
};

AISampleDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sound: PropTypes.shape({
    audio_preview: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    owner: PropTypes.string.isRequired
  }).isRequired
};

export default AISampleDialog;