import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader2, MoreVertical, Star } from 'lucide-react';
import { fetchUsers, updateUser } from '../api/APIManager';
import { VerifiedBadge } from '../assets/Icons';
import { DEFAULT_SOUND_IMAGE, DEFAULT_USER_IMAGE } from '../api/APIConfig';
import { AuthContext } from '../context/AuthContext';

const UserCard = ({ user, onUpdate }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { user: currentUser } = useContext(AuthContext);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  if (!user) return null;

  return (
    <div
      onClick={() => navigate(`/${user.username}`)}
      className="bg-bg-primary rounded-lg transition-all duration-200 cursor-pointer overflow-hidden relative"
    >
      <div className="p-4">
        <div className="relative aspect-square mb-4 overflow-hidden rounded-full">
          <img
            src={user.avatar || DEFAULT_USER_IMAGE}
            alt={user.username}
            className="w-full h-full object-cover"
            onError={(e) => {
              e.target.src = DEFAULT_SOUND_IMAGE;
            }}
          />

        </div>
        <div className="space-y-2">
           <h4 className="font-semibold truncate text-text-primary text-center">{user.username}{user.verified && (
            <VerifiedBadge/>
          )} </h4>
        </div>
      </div>
    </div>
  );
};

const UsersPage = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState({
    query: '',
    featured: '',
    verified: '',
  });
  const pageSize = 24;
  const loadingRef = useRef(false);
  const searchTimeout = useRef(null);

  const fetchUsersData = async (newPage, currentFilter = filter) => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    setLoading(true);
    
    try {
      const response = await fetchUsers(newPage, pageSize, currentFilter);
      setUsers(response.items || []);
      setTotalResults(response.total || 0);
      setTotalPages(Math.ceil((response.total || 0) / pageSize));
      setPage(newPage);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
      loadingRef.current = false;
    }
  };

  useEffect(() => {
    fetchUsersData(1);
  }, []);

  useEffect(() => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      const newFilter = { ...filter, query: searchQuery };
      setFilter(newFilter);
      fetchUsersData(1, newFilter);
    }, 500);

    return () => {
      if (searchTimeout.current) {
        clearTimeout(searchTimeout.current);
      }
    };
  }, [searchQuery]);

  const handleFilterChange = (name, value) => {
    const newFilter = { ...filter, [name]: value };
    setFilter(newFilter);
    fetchUsersData(1, newFilter);
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages && !loading) {
      fetchUsersData(newPage);
    }
  };

  const handleDirectPageInput = (e) => {
    if (e.key === 'Enter') {
      const pageNumber = parseInt(e.target.value);
      if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
        handlePageChange(pageNumber);
      }
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    
    pageNumbers.push(1);
    
    let startPage = Math.max(2, page - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages - 1, startPage + maxVisiblePages - 1);
    
    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(2, endPage - maxVisiblePages + 2);
    }
    
    if (startPage > 2) {
      pageNumbers.push('...');
    }
    
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    
    if (endPage < totalPages - 1) {
      pageNumbers.push('...');
    }
    
    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }
    
    return pageNumbers;
  };

  if (loading && page === 1) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-bg-primary">
        <Loader2 className="animate-spin h-8 w-8 text-accent-start" />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 bg-bg-primary min-h-screen">
      {/* Header with Filters */}
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
            <h1 className="text-2xl font-bold text-text-primary">Users</h1>
            {totalResults > 0 && (
              <span className="text-sm text-text-secondary">
                {totalResults.toLocaleString()} total
              </span>
            )}
          </div>
        </div>
        
        {/* Search and Filters */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <input
            type="text"
            placeholder="Search users..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="px-4 py-2 rounded-full bg-bg-secondary border border-text-primary/10 focus:outline-none focus:border-accent-end"
          />
        </div>
      </div>

      {/* Users Grid */}
      <div className="relative">
        {loading && (
          <div className="absolute inset-0 bg-black/50 backdrop-blur-sm flex justify-center items-center z-10">
            <Loader2 className="animate-spin h-8 w-8 text-accent-start" />
          </div>
        )}
        
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-6">
          {users.map((user) => (
            <UserCard 
              key={user.id} 
              user={user} 
              onUpdate={() => fetchUsersData(page)}
            />
          ))}

          {users.length === 0 && !loading && (
            <div className="col-span-full text-center py-12 text-text-secondary">
              No users found
            </div>
          )}
        </div>

        {/* Pagination Controls */}
        {users.length > 0 && (
          <div className="mt-4 flex flex-col sm:flex-row items-center justify-between gap-4 border-t border-text-primary/10 pt-4 px-4 sm:px-8 pb-4">
            <div className="flex items-center gap-2 text-sm">
              <span>Total: {totalResults} users</span>
              <span className="text-text-primary/50">|</span>
              <span>Page {page} of {totalPages}</span>
            </div>
            
            <div className="flex items-center gap-2 flex-wrap justify-center">
              <button
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1 || loading}
                className="px-3 py-1 rounded-lg border border-text-primary/15 disabled:opacity-50 hover:bg-accent-end/10"
              >
                Previous
              </button>
              
              <div className="flex items-center gap-1">
                {getPageNumbers().map((pageNum, index) => (
                  <React.Fragment key={index}>
                    {pageNum === '...' ? (
                      <span className="px-2">...</span>
                    ) : (
                      <button
                        onClick={() => handlePageChange(pageNum)}
                        disabled={loading}
                        className={`px-3 py-1 rounded-lg border ${
                          pageNum === page
                            ? 'bg-accent-end text-text-primary border-accent-end'
                            : 'border-text-primary/15 hover:bg-accent-end/10'
                        }`}
                      >
                        {pageNum}
                      </button>
                    )}
                  </React.Fragment>
                ))}
              </div>

              <button
                onClick={() => handlePageChange(page + 1)}
                disabled={page === totalPages || loading}
                className="px-3 py-1 rounded-lg border border-text-primary/15 disabled:opacity-50 hover:bg-accent-end/10"
              >
                Next
              </button>

              <input
                type="number"
                min="1"
                max={totalPages}
                className="w-16 px-2 py-1 rounded-lg border border-text-primary/15 bg-transparent text-center"
                placeholder={page.toString()}
                onKeyDown={handleDirectPageInput}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UsersPage;