// AudioNavigationContext.js
import React, { createContext, useContext, useState, useCallback, useRef } from 'react';

const AudioNavigationContext = createContext();

export const AudioNavigationProvider = ({ children }) => {
  const [currentPlayingSound, setCurrentPlayingSound] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const soundsRef = useRef([]);

  const setSoundsList = useCallback((sounds) => {
    soundsRef.current = sounds;
  }, []);

  const navigateToSound = useCallback((direction) => {
    if (!currentPlayingSound || soundsRef.current.length === 0) return null;

    const currentIndex = soundsRef.current.findIndex(sound => sound.id === currentPlayingSound.id);
    if (currentIndex === -1) return null;

    let newIndex;
    if (direction === 'next') {
      newIndex = (currentIndex + 1) % soundsRef.current.length;
    } else if (direction === 'previous') {
      newIndex = (currentIndex - 1 + soundsRef.current.length) % soundsRef.current.length;
    }

    const newSound = soundsRef.current[newIndex];
    return newSound;
  }, [currentPlayingSound]);

  const value = {
    currentPlayingSound,
    setCurrentPlayingSound,
    isPlaying,
    setIsPlaying,
    setSoundsList,
    navigateToSound
  };

  return (
    <AudioNavigationContext.Provider value={value}>
      {children}
    </AudioNavigationContext.Provider>
  );
};

export const useAudioNavigation = () => {
  const context = useContext(AudioNavigationContext);
  if (!context) {
    throw new Error('useAudioNavigation must be used within an AudioNavigationProvider');
  }
  return context;
};
