// src/context/ToastContext.js
import React, { createContext, useContext, useState, useCallback } from 'react';

const ToastContext = createContext(null);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

const Toast = ({ message, type, onClose }) => (
  <div className="fixed bottom-4 right-4 z-50 max-w-md bg-opacity-90" role="alert">
    <div 
      className={`p-4 rounded shadow-md ${
        type === 'error' ? 'bg-red-500 text-white' : 
        type === 'success' ? 'bg-green-500 text-white' : 
        'bg-gray-800 text-white'
      }`}
    >
      <div className="flex items-start justify-between">
        <p className="flex-1 mr-2 text-sm">
          {message}
        </p>
        <button
          onClick={onClose}
          className="text-white opacity-70 hover:opacity-100"
        >
          ✕
        </button>
      </div>
    </div>
  </div>
);

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  const showToast = useCallback((message, type = 'info') => {
    // Clear any existing toast and timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    
    // Set the new toast
    setToast({ message, type });

    // Set new timeout
    const newTimeoutId = setTimeout(() => {
      setToast(null);
    }, 4000);

    setTimeoutId(newTimeoutId);

    // Cleanup function for unmounting
    return () => {
      if (newTimeoutId) {
        clearTimeout(newTimeoutId);
      }
    };
  }, [timeoutId]);

  const closeToast = useCallback(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setToast(null);
  }, [timeoutId]);

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={closeToast}
        />
      )}
    </ToastContext.Provider>
  );
};