import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Plus } from 'lucide-react';

const SearchIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
    </svg>
);

const DropdownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
    </svg>
);

export const CustomMultiSelect = ({     
    options, 
    value, 
    onChange, 
    placeholder, 
    label, 
    isMulti = true, 
    required = false, 
    hideSearch = false, 
    canClear = true,
    onCreate = null, // New prop for handling creation
    creatable = false // New prop to enable/disable creation
    }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0, direction: 'down' });
    const [isCreating, setIsCreating] = useState(false);
    
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    const containerRef = useRef(null);
    const selectedItemsRef = useRef(null);

    const safeValue = Array.isArray(value) ? value : [];
    const DROPDOWN_HEIGHT = 240;
    const MARGIN = 8;

    useEffect(() => {
        if (selectedItemsRef.current) {
            const container = selectedItemsRef.current;
            container.scrollLeft = container.scrollWidth;
        }
    }, [safeValue]);

    useEffect(() => {
        if (isOpen && containerRef.current) {
            const dialogContent = containerRef.current.closest('.dialog-content');
            const rect = containerRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const spaceBelow = viewportHeight - rect.bottom;
            const spaceAbove = rect.top;
            const direction = spaceBelow < DROPDOWN_HEIGHT && spaceAbove > spaceBelow ? 'up' : 'down';

            let scrollTop = 0;
            if (dialogContent) {
                scrollTop = dialogContent.scrollTop;
            } else {
                scrollTop = window.scrollY;
            }

            setDropdownPosition({
                top: direction === 'up' 
                    ? rect.top - DROPDOWN_HEIGHT - MARGIN
                    : rect.bottom + MARGIN,
                left: rect.left,
                width: rect.width,
                direction
            });
        }
    }, [isOpen]);

    useEffect(() => {
        const handleScroll = () => {
            if (isOpen && containerRef.current) {
                const rect = containerRef.current.getBoundingClientRect();
                const viewportHeight = window.innerHeight;
                const spaceBelow = viewportHeight - rect.bottom;
                const spaceAbove = rect.top;
                const direction = spaceBelow < DROPDOWN_HEIGHT && spaceAbove > spaceBelow ? 'up' : 'down';

                setDropdownPosition({
                    top: direction === 'up' 
                        ? rect.top - DROPDOWN_HEIGHT - MARGIN
                        : rect.bottom + MARGIN,
                    left: rect.left,
                    width: rect.width,
                    direction
                });
            }
        };

        const updatePosition = () => {
            if (!containerRef.current) return;
            
            const dialogContent = containerRef.current.closest('.dialog-content');
            const rect = containerRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;
            const spaceBelow = viewportHeight - rect.bottom;
            const spaceAbove = rect.top;
            const direction = spaceBelow < DROPDOWN_HEIGHT && spaceAbove > spaceBelow ? 'up' : 'down';

            let scrollTop = 0;
            if (dialogContent) {
                scrollTop = dialogContent.scrollTop;
            } else {
                scrollTop = window.scrollY;
            }

            setDropdownPosition({
                top: direction === 'up' 
                    ? rect.top + scrollTop - DROPDOWN_HEIGHT - MARGIN
                    : rect.bottom + scrollTop + MARGIN,
                left: rect.left + window.scrollX,
                width: rect.width,
                direction
            });
        };

        const handleResize = () => {
            if (isOpen) {
                updatePosition();
            }
        };

        if (isOpen) {
            const dialogContent = containerRef.current?.closest('.dialog-content');
            if (dialogContent) {
                dialogContent.addEventListener('scroll', handleScroll, true);
            }
            window.addEventListener('scroll', handleScroll, true);
            window.addEventListener('resize', handleResize);
        }

        return () => {
            const dialogContent = containerRef.current?.closest('.dialog-content');
            if (dialogContent) {
                dialogContent.removeEventListener('scroll', handleScroll, true);
            }
            window.removeEventListener('scroll', handleScroll, true);
            window.removeEventListener('resize', handleResize);
        };
    }, [isOpen]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current && 
                !dropdownRef.current.contains(event.target) &&
                containerRef.current &&
                !containerRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            setSearchTerm('');
            setHighlightedIndex(-1);
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [isOpen]);

    const filteredOptions = options.filter(option =>
        option.name.toLowerCase().includes(searchTerm.toLowerCase())
    ).sort((a, b) => {
        const aSelected = safeValue.some(v => v.id === a.id);
        const bSelected = safeValue.some(v => v.id === b.id);
        if (aSelected && !bSelected) return -1;
        if (!aSelected && bSelected) return 1;
        return 0;
    });

    const handleCreate = async () => {
        if (!searchTerm.trim() || !onCreate) return;
        
        setIsCreating(true);
        try {
            const newItem = await onCreate({ name: searchTerm.trim() });
            if (newItem) {
                toggleOption(newItem);
            }
        } catch (error) {
            console.error('Failed to create item:', error);
        } finally {
            setIsCreating(false);
            setSearchTerm('');
        }
    };

    const toggleOption = (option) => {
        if (isMulti) {
            const newValue = safeValue.some(v => v.id === option.id)
                ? safeValue.filter(v => v.id !== option.id)
                : [...safeValue, option];
            onChange(newValue);
        } else {
            onChange([option]);
            setIsOpen(false);
        }
        setSearchTerm('');
        setHighlightedIndex(-1);
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const removeOption = (optionToRemove, event) => {
        event.stopPropagation();
        const newValue = safeValue.filter(v => v.id !== optionToRemove.id);
        onChange(newValue);
    };
    const handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (highlightedIndex >= 0 && filteredOptions.length > 0) {
                toggleOption(filteredOptions[highlightedIndex]);
            } else if (creatable && searchTerm && !filteredOptions.length) {
                await handleCreate();
            }
        } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            setHighlightedIndex((prevIndex) =>
                prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : prevIndex
            );
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setHighlightedIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : 0
            );
        } else if (e.key === 'Escape') {
            setIsOpen(false);
        }
    };

    const renderSelectedItems = () => {
        if (safeValue.length === 0) {
            return <span className="text-gray-400 text-sm">{placeholder}</span>;
        }

        return (
            <div 
                ref={selectedItemsRef}
                className="flex items-center space-x-1 overflow-x-auto hide-scrollbar"
                style={{ msOverflowStyle: 'none', scrollbarWidth: 'none' }}
            >
                {safeValue.map(item => (
                    <span 
                        key={item.id}
                        className={`flex-shrink-0 text-white text-xs whitespace-nowrap flex items-center
                            ${isMulti ? 'bg-white/20 rounded-full px-1.5 py-0.5' : ''}`}
                    >
                        {item.name}
                        {canClear && (
                            <button
                                className="ml-1 text-white hover:text-red-500 focus:outline-none"
                                onClick={(e) => removeOption(item, e)}
                            >
                                ×
                            </button>
                        )}
                    </span>
                ))}
            </div>
        );
    };

    const renderDropdown = () => {
        if (!isOpen) return null;

        const dropdown = (
            <div 
                ref={dropdownRef}
                className="fixed bg-bg-primary border border-text-primary/10 rounded-md shadow-lg overflow-hidden"
                style={{
                    position: 'fixed',
                    top: `${dropdownPosition.top}px`,
                    left: `${dropdownPosition.left}px`,
                    width: `${dropdownPosition.width}px`,
                    zIndex: 999999
                }}
            >
                {!hideSearch && (
                    <div className="relative">
                        <input
                            ref={inputRef}
                            type="text"
                            className="w-full p-1.5 pl-7 text-xs border-0 focus:outline-none focus:ring-0 bg-bg-primary text-text-primary placeholder-text-secondary"
                            placeholder={`Search ${placeholder.toLowerCase()}...`}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyDown={handleKeyDown}
                            onClick={(e) => e.stopPropagation()}
                        />
                        <div className="absolute left-2 top-1/2 transform -translate-y-1/2">
                            <SearchIcon />
                        </div>
                    </div>
                )}
                <div className={`overflow-y-auto ${hideSearch ? 'max-h-56' : 'max-h-48'}`}>
                    {filteredOptions.map((option, index) => (
                        <div
                            key={option.id}
                            className={`
                                p-1.5 cursor-pointer text-xs
                                transition-colors duration-150 ease-in-out
                                ${safeValue.some(v => v.id === option.id) ? 'bg-white/15 text-white' : 'text-text-primary'}
                                ${index === highlightedIndex ? 'bg-white/30' : 'hover:bg-black/50 hover:text-white'}
                            `}
                            onClick={() => toggleOption(option)}
                        >
                            {option.name}
                        </div>
                    ))}
                    
                    {creatable && searchTerm && !filteredOptions.length && (
                        <div
                            className={`
                                p-1.5 cursor-pointer text-xs flex items-center gap-2
                                transition-colors duration-150 ease-in-out text-white
                                hover:text-white/50 hover:text-white
                            `}
                            onClick={handleCreate}
                        >
                            <Plus className="h-3 w-3" />
                            Create "{searchTerm}"
                            {isCreating && <span className="ml-2">...</span>}
                        </div>
                    )}
                </div>
            </div>
        );

        return createPortal(dropdown, document.body);
    };

    return (
        <div className="w-full ">
            {label && (
                <label className="block text-sm font-medium text-text-secondary mb-2">
                    {label}
                </label>
            )}
            <div 
                className="relative w-full" 
                ref={containerRef}
            >
                <div
                    className={`
                        border border-white/20 hover:border-accent-end 
                        rounded-full px-3 py-1.5 cursor-pointer h-8 flex items-center justify-between 
                        text-xs transition-all duration-300 ease-in-out
                        ${isOpen ? 'ring-1 ring-bg-white/50' : ''}
                    `}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <div className="flex-grow overflow-hidden">
                        {renderSelectedItems()}
                    </div>
                    <div className="flex-shrink-0 ml-2">
                        <DropdownIcon />
                    </div>
                </div>
                {renderDropdown()}
            </div>
        </div>
    );
};

export default CustomMultiSelect;