import React, { useState, useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { Play, Pause, Download, Share2 } from 'lucide-react';

// Particle Background Component
const ParticleBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    class Particle {
      constructor() {
        this.reset();
      }

      reset() {
        this.x = Math.random() * canvas.width;
        this.y = canvas.height + 10;
        this.size = Math.random() * 3 + 1;
        this.speedY = Math.random() * 2 + 1;
        this.speedX = Math.random() * 2 - 1;
        this.opacity = Math.random() * 0.5 + 0.2;
      }

      update() {
        this.y -= this.speedY;
        this.x += this.speedX;
        if (this.y < -10) this.reset();
      }

      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(99, 102, 241, ${this.opacity})`;
        ctx.fill();
      }
    }

    const particles = Array.from({ length: 50 }, () => new Particle());

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particles.forEach((particle) => {
        particle.update();
        particle.draw();
      });
      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="fixed top-0 left-0 w-full h-full pointer-events-none z-0"
    />
  );
};

// Audio Player Component with WaveSurfer
const AudioPlayer = ({ audioUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null);

  useEffect(() => {
    waveSurferRef.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: '#888',
      progressColor: '#4F46E5',
      cursorColor: '#FFFFFF',
      barWidth: 2,
      height: 80,
      responsive: true,
    });

    waveSurferRef.current.load(audioUrl);

    return () => {
      if (waveSurferRef.current) {
        waveSurferRef.current.destroy();
        waveSurferRef.current = null;
      }
    };
  }, [audioUrl]);

  const handlePlayPause = () => {
    if (waveSurferRef.current) {
      if (isPlaying) {
        waveSurferRef.current.pause();
      } else {
        waveSurferRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(audioUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'generated-audio.mp3';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'Generated Audio',
          url: audioUrl,
        });
      } else {
        await navigator.clipboard.writeText(audioUrl);
        console.log('URL copied to clipboard');
      }
    } catch (error) {
      console.error('Share failed:', error);
    }
  };

  return (
    <div className="bg-gray-900 rounded-lg p-4 mb-4">
      <div ref={waveformRef} className="w-full mb-4"></div>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <button
            onClick={handlePlayPause}
            className="w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full hover:bg-indigo-700 transition-colors"
          >
            {isPlaying ? (
              <Pause className="w-5 h-5 text-white" />
            ) : (
              <Play className="w-5 h-5 text-white" />
            )}
          </button>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={handleDownload}
            className="p-2 text-gray-300 hover:text-white transition-colors"
            title="Download"
          >
            <Download className="w-5 h-5" />
          </button>
          <button
            onClick={handleShare}
            className="p-2 text-gray-300 hover:text-white transition-colors"
            title="Share"
          >
            <Share2 className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
};

// Main Component
const AIGenerator = () => {
  const [formData, setFormData] = useState({
    prompt: '',
    model: 'chirp-v4',
    lyric: '',
    custom: false,
    instrumental: false,
    title: '',
    style: '',
    styleNegative: '',
    audioUrl: '',
    personaId: '',
    continueAt: 10,
    callbackUrl: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [generatedSamples, setGeneratedSamples] = useState(null);
  const [error, setError] = useState('');
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const toggleAdvancedMode = () => setIsAdvancedMode((prev) => !prev);

  const handleGenerate = async () => {
    setIsLoading(true);
    setError('');
    setGeneratedSamples(null);

    try {
      const payload = {
        action: formData.audioUrl ? 'extend' : 'generate',
        prompt: formData.prompt,
        model: formData.model,
        lyric: formData.lyric,
        custom: formData.custom,
        instrumental: formData.instrumental,
        title: formData.title,
        style: formData.style,
        style_negative: formData.styleNegative,
        audio_id: formData.audioUrl,
        persona_id: formData.personaId,
        continue_at: formData.continueAt,
        callback_url: formData.callbackUrl,
      };

      const response = await fetch('https://api.acedata.cloud/suno/audios', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer 641812e5c49949ebaad381cf2ac917f6',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data.success) {
        setGeneratedSamples(data.data);
      } else {
        setError('Failed to generate audio: ' + data.error.message);
      }
    } catch (err) {
      setError('An unexpected error occurred.');
      console.error('Request failed:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen w-full bg-transparent overflow-hidden relative">
      {isLoading && <ParticleBackground />}
      <div className="relative container mx-auto px-4 py-8">
        <div className="w-full max-w-lg mx-auto bg-gray-800 rounded-lg shadow-lg p-6">
          <h2 className="text-xl font-bold text-white mb-6 text-center">
            AI Music Generator
          </h2>
          <button
            onClick={toggleAdvancedMode}
            className="mb-4 bg-gray-700 text-white px-4 py-2 rounded hover:bg-gray-600"
          >
            {isAdvancedMode ? 'Basic Mode' : 'Advanced Mode'}
          </button>
          <div className="space-y-4">
            {[
              { label: 'Prompt', name: 'prompt', type: 'textarea', tooltip: 'Enter music prompt' },
              { label: 'Model', name: 'model', type: 'text', tooltip: 'Specify the model' },
              { label: 'Lyric', name: 'lyric', type: 'textarea', tooltip: 'Lyrics for music' },
              { label: 'Custom', name: 'custom', type: 'checkbox', tooltip: 'Custom music' },
              { label: 'Instrumental', name: 'instrumental', type: 'checkbox', tooltip: 'Instrumental only' },
              ...(isAdvancedMode
                ? [
                    { label: 'Style Negative', name: 'styleNegative', type: 'text', tooltip: 'Style to avoid' },
                    { label: 'Audio URL', name: 'audioUrl', type: 'text', tooltip: 'Audio to extend' },
                    { label: 'Persona ID', name: 'personaId', type: 'text', tooltip: 'Singer style ID' },
                    { label: 'Continue At', name: 'continueAt', type: 'number', tooltip: 'Time to continue from' },
                    { label: 'Callback URL', name: 'callbackUrl', type: 'text', tooltip: 'Callback URL' },
                  ]
                : []),
            ].map((field, index) => (
              <div key={index} className="relative group">
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  {field.label}
                  <span className="ml-2 text-gray-400 cursor-help" title={field.tooltip}>
                    ⓘ
                  </span>
                </label>
                {field.type === 'textarea' ? (
                  <textarea
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 rounded-md bg-gray-700 border border-gray-600 text-white resize-none"
                  />
                ) : field.type === 'checkbox' ? (
                  <input
                    type="checkbox"
                    name={field.name}
                    checked={formData[field.name]}
                    onChange={handleInputChange}
                    className="h-5 w-5"
                  />
                ) : (
                  <input
                    type={field.type}
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 rounded-md bg-gray-700 border border-gray-600 text-white"
                  />
                )}
              </div>
            ))}
            <button
              onClick={handleGenerate}
              disabled={isLoading}
              className="w-full h-12 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50"
            >
              {isLoading ? 'Generating...' : 'Generate'}
            </button>
            {error && <p className="text-red-500 mt-2">{error}</p>}
            {generatedSamples && (
              <div className="mt-4">
                <h3 className="text-lg text-white mb-2">Generated Samples</h3>
                {generatedSamples.map((sample, index) => (
                  <AudioPlayer key={index} audioUrl={sample.audio_url} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIGenerator;
