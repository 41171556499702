// utils/sanitizeFilename.js

export const sanitizeFilename = (filename) => {
    if (!filename) return '';
    
    // Get the file extension
    const lastDot = filename.lastIndexOf('.');
    const ext = lastDot !== -1 ? filename.slice(lastDot) : '';
    const nameWithoutExt = lastDot !== -1 ? filename.slice(0, lastDot) : filename;
    
    // Replace unsafe characters and spaces
    const sanitized = nameWithoutExt
      // Replace spaces and unsafe characters with hyphens
      .replace(/[^a-zA-Z0-9-._]/g, '-')
      // Replace multiple consecutive hyphens with a single hyphen
      .replace(/-+/g, '-')
      // Remove leading/trailing hyphens
      .replace(/^-+|-+$/g, '')
      // Convert to lowercase
      .toLowerCase();
      
    // Ensure the filename isn't too long (max 255 chars including extension)
    const maxLength = 255 - ext.length;
    const truncated = sanitized.slice(0, maxLength);
    
    // Reassemble filename with extension
    return truncated + ext.toLowerCase();
  };
  
  // Helper function to create a sanitized File object
  export const createSanitizedFile = (file) => {
    if (!file) return null;
    
    const sanitizedFilename = sanitizeFilename(file.name);
    return new File([file], sanitizedFilename, { type: file.type });
  };