// audioUtils.js

export const convertWavToMp3 = async (wavFile) => {
  // First check if window.lamejs is available
  if (!window.lamejs) {
      // Load lamejs if not already loaded
      await new Promise((resolve, reject) => {
          const script = document.createElement('script');
          script.src = 'https://cdn.jsdelivr.net/npm/lamejs@1.2.1/lame.min.js';
          script.onload = resolve;
          script.onerror = reject;
          document.head.appendChild(script);
      });
  }

  try {
      // Create audio context
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      
      // Read WAV file
      const arrayBuffer = await wavFile.arrayBuffer();
      
      // Decode audio
      const originalBuffer = await audioContext.decodeAudioData(arrayBuffer);
      
      // Create offline context
      const offlineContext = new OfflineAudioContext({
          numberOfChannels: originalBuffer.numberOfChannels,
          length: originalBuffer.length,
          sampleRate: originalBuffer.sampleRate
      });

      // Create source
      const source = offlineContext.createBufferSource();
      source.buffer = originalBuffer;
      source.connect(offlineContext.destination);
      
      // Start rendering
      source.start(0);
      const renderedBuffer = await offlineContext.startRendering();

      // Initialize LAME encoder
      const channels = renderedBuffer.numberOfChannels;
      const sampleRate = renderedBuffer.sampleRate;
      const kbps = 128;
      
      // Create the mp3 encoder using the global Lame object
      const mp3encoder = new window.lamejs.Mp3Encoder(
          channels,
          sampleRate,
          kbps
      );
      
      // Extract channel data
      const left = renderedBuffer.getChannelData(0);
      const right = channels > 1 ? renderedBuffer.getChannelData(1) : left;
      const mp3Data = [];
      
      // Process in smaller chunks to avoid memory issues
      const SAMPLES_PER_CHUNK = 1152; // LAME constant
      
      for (let i = 0; i < left.length; i += SAMPLES_PER_CHUNK) {
          const leftChunk = new Int16Array(SAMPLES_PER_CHUNK);
          const rightChunk = new Int16Array(SAMPLES_PER_CHUNK);
          
          for (let j = 0; j < SAMPLES_PER_CHUNK && (i + j) < left.length; j++) {
              // Scale to 16-bit signed integer and clip
              leftChunk[j] = Math.max(-32768, Math.min(32767, Math.round(left[i + j] * 32768)));
              rightChunk[j] = Math.max(-32768, Math.min(32767, Math.round(right[i + j] * 32768)));
          }
          
          const mp3buf = mp3encoder.encodeBuffer(leftChunk, rightChunk);
          if (mp3buf.length > 0) {
              mp3Data.push(mp3buf);
          }
      }
      
      // Get the last few bytes of MP3 data
      const finalMp3buf = mp3encoder.flush();
      if (finalMp3buf.length > 0) {
          mp3Data.push(finalMp3buf);
      }
      
      // Combine all MP3 data chunks
      const blob = new Blob(mp3Data, { type: 'audio/mp3' });
      
      // Create the final MP3 file
      return new File([blob], wavFile.name.replace('.wav', '_compressed.mp3'), {
          type: 'audio/mp3'
      });

  } catch (error) {
      console.error('Error in audio conversion:', error);
      throw error;
  }
};