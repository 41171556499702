import React, { useState, useEffect, useMemo, useRef } from 'react';
import { CustomMultiSelect } from '../../components/CustomMultiSelect';
import { fetchGenres, fetchInstruments, fetchSoundTypes } from '../../api/APIManager';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Analytics } from '../../api/Analytics/AnalyticsService';

const VALID_KEYS = [
    "C Major", "Db Major", "D Major", "Eb Major", "E Major", "F Major",
    "Gb Major", "G Major", "Ab Major", "A Major", "Bb Major", "B Major",
    "C Minor", "Db Minor", "D Minor", "Eb Minor", "E Minor", "F Minor",
    "Gb Minor", "G Minor", "Ab Minor", "A Minor", "Bb Minor", "B Minor"
].map((key, index) => ({ id: index.toString(), name: key }));

const SORT_OPTIONS = [
  { id: 'recent', name: 'Most Recent' },
  { id: 'popular', name: 'Most Popular' }
];

const SearchIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 text-text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
    </svg>
);

export const SearchRow = ({ onSearch, initialFilters, title }) => {
  const [filters, setFilters] = useState(initialFilters || {
    key: [],
    instruments: [],
    genres: [],
    soundType: [],
    searchText: '',
    sortBy: [{ id: 'recent', name: 'Most Recent' }]
  });

  const [availableGenres, setAvailableGenres] = useState([]);
  const [availableInstruments, setAvailableInstruments] = useState([]);
  const [availableSoundTypes, setAvailableSoundTypes] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);

  useEffect(() => {
    setFilters(prev => ({
      ...initialFilters,
      sortBy: [initialFilters?.sort_by_likes ? 
        { id: 'popular', name: 'Most Popular' } : 
        { id: 'recent', name: 'Most Recent' }
      ]
    }));

    const loadData = async () => {
      const [fetchedGenres, fetchedInstruments, fetchedSoundTypes] = await Promise.all([
        fetchGenres(true),
        fetchInstruments(true),
        fetchSoundTypes()
      ]);
      setAvailableGenres(fetchedGenres.map(g => ({ ...g, type: 'genres' })));
      setAvailableInstruments(fetchedInstruments.map(i => ({ ...i, type: 'instruments' })));
      setAvailableSoundTypes(fetchedSoundTypes);
    };
    loadData();
  }, [initialFilters]);

  const handleFilterChange = (filterName, value, shouldNotSearch) => {
    const newFilters = {
      ...filters,
      [filterName]: value
    };
    setFilters(newFilters);
    Analytics.trackSearch(newFilters);
    if (!shouldNotSearch) {
      onSearch(newFilters);
    }
  };

  const handleSearch = () => {
    Analytics.trackSearch(filters);
    onSearch(filters);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleClearAll = () => {
    const clearedFilters = {
      key: [],
      instruments: [],
      genres: [],
      soundType: [],
      searchText: '',
      sortBy: [{ id: 'recent', name: 'Most Recent' }]
    };
    setFilters(clearedFilters);
    onSearch(clearedFilters);
  };

  const handlePillClick = (item, event) => {
    if (!isDragging) {
      toggleSelection(item);
    }
  };

  const toggleSelection = (item) => {
    const type = item.type;
    setFilters(prevFilters => {
      const currentSelection = prevFilters[type] || [];
      const isSelected = currentSelection.some(selected => selected.id === item.id);
      
      const newFilters = {
        ...prevFilters,
        [type]: isSelected 
          ? currentSelection.filter(selected => selected.id !== item.id)
          : [...currentSelection, item]
      };
      Analytics.trackSearch(newFilters);
      onSearch(newFilters);
      return newFilters;
    });
  };

  const isSelected = (item) => {
    return filters[item.type]?.some(selected => selected.id === item.id);
  };

  const allItems = useMemo(() => {
    const combined = [...availableInstruments, ...availableGenres];
    return combined.sort((a, b) => {
      const aSelected = isSelected(a);
      const bSelected = isSelected(b);
  
      if (aSelected && !bSelected) return -1;
      if (!aSelected && bSelected) return 1;
  
      return (b.sound_count || 0) - (a.sound_count || 0);
    });
  }, [availableInstruments, availableGenres, filters]);

  const PillList = ({ items }) => {
    const scrollRef = useRef(null);
    const hasMore = items.length > 10;

    return (
      <div className="flex items-start gap-2 w-full">
        <div 
          ref={scrollRef}
          className={`flex-1 ${isExpanded ? '' : 'overflow-x-auto scrollable-content'}`}
          style={{ WebkitOverflowScrolling: 'touch' }}
        >
          <div 
            className={`inline-flex gap-2 min-w-full py-1 ${
              isExpanded ? 'flex-wrap' : 'flex-nowrap'
            }`}
          >
            {items.map(item => (
              <button
                key={item.id}
                onClick={() => handlePillClick(item)}
                className={`shrink-0 px-3 py-1 rounded-full text-xs transition-colors duration-200 whitespace-nowrap select-none ${
                  isSelected(item)
                    ? 'bg-accent-end text-text-primary'
                    : 'border border-text-primary/15 text-text-primary hover:bg-accent-end/10'
                }`}
              >
                {item.name + (item.sound_count ? ` (${item.sound_count})` : '')} 
              </button>
            ))}
          </div>
        </div>
        {hasMore && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="shrink-0 flex items-center justify-center h-7 px-2 text-text-primary hover:bg-accent-end/10 transition-colors duration-200 rounded-full border border-text-primary/15"
          >
            {isExpanded ? (
              <ChevronUp className="w-4 h-4" />
            ) : (
              <ChevronDown className="w-4 h-4" />
            )}
          </button>
        )}
      </div>
    );
  };


  const getActiveFilterCount = () => {
    return (
      (filters?.instruments?.length || 0) +
      (filters?.genres?.length || 0) +
      (filters?.key?.length ? 1 : 0) +
      (filters?.soundType?.length ? 1 : 0)
    );
  };

  return (
    <div className="w-full bg-transparent rounded-lg space-y-4">
      {/* Mobile Search Controls */}
      <div className="sm:hidden space-y-3">
        {/* Sort Row */}
        <div className="flex flex-col gap-3">

        {/* Filter Toggle Button */}
        <button
          onClick={() => setIsFilterExpanded(!isFilterExpanded)}
          className="w-full flex items-center justify-between px-4 py-2 bg-transparent border border-text-primary/15 rounded-full text-text-primary"
        >
          <span className="text-xs font-medium">
            Filters {getActiveFilterCount() > 0 && `(${getActiveFilterCount()})`}
          </span>
          {isFilterExpanded ? (
            <ChevronUp className="w-4 h-4" />
          ) : (
            <ChevronDown className="w-4 h-4" />
          )}
        </button>
      </div>
      {/* Mobile Filters */}
      {isFilterExpanded && (
        <div className="sm:hidden space-y-3 p-4 border border-text-primary/15 rounded-lg">
          <div className="space-y-3">
            <div className="w-full">
              <CustomMultiSelect
                options={availableGenres}
                value={filters.genres}
                onChange={(selected) => handleFilterChange('genres', selected)}
                placeholder="Genre"
                isMulti={true}
              />
            </div>
            <div className="w-full">
              <CustomMultiSelect
                options={availableInstruments}
                value={filters.instruments}
                onChange={(selected) => handleFilterChange('instruments', selected)}
                placeholder="Instruments"
                isMulti={true}
              />
            </div>
            <div className="w-full">
              <CustomMultiSelect
                options={VALID_KEYS}
                value={filters.key}
                onChange={(selected) => handleFilterChange('key', selected)}
                placeholder="Key"
                isMulti={false}
              />
            </div>
            <div className="w-full">
              <CustomMultiSelect
                options={availableSoundTypes}
                value={filters.soundType}
                onChange={(selected) => handleFilterChange('soundType', selected)}
                placeholder="Type"
                isMulti={false}
                hideSearch={true}
              />
            </div>
          </div>
        </div>
      )}

          {/* Sort Dropdown */}
          <div className="w-full">
            <CustomMultiSelect
              options={SORT_OPTIONS}
              value={filters.sortBy}
              onChange={(selected) => handleFilterChange('sortBy', selected)}
              placeholder="Sort By"
              isMulti={false}
              hideSearch={true}
              canClear={false}
            />
          </div>



                {/* Action Buttons */}
                <div className="flex gap-2">
            <button
              className="flex-1 h-8 px-4 rounded-full text-xs font-medium border border-text-primary/15 text-text-primary hover:bg-accent-end/10 transition-duration-200"
              onClick={handleClearAll}
            >
              Clear All
            </button>
            <button
              className="flex-1 h-8 px-6 rounded-full text-xs font-medium bg-accent-end text-text-primary hover:opacity-90 transition-duration-200"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
        </div>
      {/* Desktop Layout */}
      <div className="hidden sm:flex flex-wrap gap-3 justify-center items-center">

        {/* Search Input */}
        <div className="relative w-64">
          <input
            type="text"
            placeholder={`Search${title ? ` in ${title.toLowerCase()}` : ''}...`}
            className="w-full bg-transparent border border-text-primary/15 rounded-full h-8 pl-8 pr-3 text-xs text-text-primary placeholder-text-primary/70 focus:outline-none focus:ring-1 focus:ring-text-primary"
            onChange={(e) => handleFilterChange('searchText', e.target.value, true)}
            onKeyPress={handleKeyPress}
            value={filters.searchText}
          />
          <div className="absolute left-2.5 top-1/2 transform -translate-y-1/2">
            <SearchIcon />
          </div>
        </div>
        
        {/* Genre Dropdown */}
        <div className="w-48">
          <CustomMultiSelect
            options={availableGenres}
            value={filters.genres}
            onChange={(selected) => handleFilterChange('genres', selected)}
            placeholder="Genre"
            isMulti={true}
          />
        </div>

        {/* Instrument Dropdown */}
        <div className="w-48">
          <CustomMultiSelect
            options={availableInstruments}
            value={filters.instruments}
            onChange={(selected) => handleFilterChange('instruments', selected)}
            placeholder="Instruments"
            isMulti={true}
          />
        </div>

        {/* Key Dropdown */}
        <div className="w-32">
          <CustomMultiSelect
            options={VALID_KEYS}
            value={filters.key}
            onChange={(selected) => handleFilterChange('key', selected)}
            placeholder="Key"
            isMulti={false}
          />
        </div>

        {/* Sound Type Dropdown */}
        <div className="w-36">
          <CustomMultiSelect
            options={availableSoundTypes}
            value={filters.soundType}
            onChange={(selected) => handleFilterChange('soundType', selected)}
            placeholder="Type"
            isMulti={false}
            hideSearch={true}
          />
        </div>

        {/* Sort Dropdown */}
        <div className="w-40">
          <CustomMultiSelect
            options={SORT_OPTIONS}
            value={filters.sortBy}
            onChange={(selected) => handleFilterChange('sortBy', selected)}
            placeholder="Sort By"
            isMulti={false}
            hideSearch={true}
            canClear={false}
          />
        </div>

        {/* Action Buttons */}
        <button
          className="h-8 px-4 rounded-full text-xs font-medium border border-text-primary/15 text-text-primary hover:bg-accent-end/10 transition-duration-200"
          onClick={handleClearAll}
        >
          Clear All
        </button>
        <button
          className="h-8 px-6 rounded-full text-xs font-medium bg-accent-end text-text-primary hover:opacity-90 transition-duration-200"
          onClick={handleSearch}
        >
          Search
        </button>
      </div>



      {/* Row 2: Filter Pills */}
      <div className="space-y-2">
        <h3 className="text-xs font-medium text-text-primary">Filters</h3>
        <PillList items={allItems} />
      </div>
    </div>
  );
};

export default SearchRow;