import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastProvider, useToast } from './context/ToastContext';
import { initializeToast } from './api/APIManager';
import Layout from './components/Layout';
import Index from './pages/Landing';
import Home from './pages/Explore';
import Search from './pages/Search';
import PurchasedSounds from './pages/PurchasedSounds';
import Upload from './pages/Upload/Upload';
import EditSound from './pages/EditSound';
import BatchUpload from './pages/BatchUpload/BatchUpload';
import BatchEdit from './pages/BatchUpload/BatchEdit';
import Likes from './pages/Likes';
import Uploaded from './pages/Uploaded';
import PrivateRoute from './components/PrivateRoute';
import UserProfile from './pages/UserProfile/UserProfile';
import CreateSoundPack from './pages/CreateSoundPack';
import SoundPacksPage from './pages/SoundPacks';
import UsersPage from './pages/UsersPage';
import AIGenerator from './pages/AIGenerator';
import AccountPage from './pages/Account/AccountPage';
import { InstrumentsManager, TagsManager, GenresManager } from './pages/MetaDataManager/MetadataPages';
import AnalyticsComponent from './api/Analytics/Analytics';
import { AudioNavigationProvider } from './context/AudioNavigationContext';
import './App.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const ToastInitializer = ({ children }) => {
  const { showToast } = useToast();
  
  useEffect(() => {
    initializeToast(showToast);
  }, [showToast]);

  return <>{children}</>;
};

const AppRoutes = () => {
  useEffect(() => {
    const preventDesktopZoom = (e) => {
      if (!('ontouchstart' in window)) {
        if ((e.ctrlKey || e.metaKey) && e.type === 'wheel') {
          e.preventDefault();
        }
      }
    };
  
    const preventDesktopSwipe = (e) => {
      if (!('ontouchstart' in window) && e.scale !== undefined && e.scale !== 1) {
        e.preventDefault();
      }
    };
  
    // Add event listeners to prevent zoom/swipe
    document.addEventListener('wheel', preventDesktopZoom, { passive: false });
    document.addEventListener('gesturestart', preventDesktopSwipe, { passive: false });
  
    return () => {
      // Clean up listeners
      document.removeEventListener('wheel', preventDesktopZoom);
      document.removeEventListener('gesturestart', preventDesktopSwipe);
    };
  }, []);
  

  return (
    <Router>
      <AnalyticsComponent />
      <Routes>
        <Route path="/landing" element={<Index />} />
        <Route path="/" element={<PrivateRoute><Layout /></PrivateRoute>}>
          <Route index element={<Home />} />
          <Route path="/search" element={<Search />} />
          <Route path="/mysoundpacks" element={<SoundPacksPage mySoundPacks={true} />} />
          <Route path="/soundpacks" element={<SoundPacksPage />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/soundpacks/create" element={<CreateSoundPack />} />
          <Route path="/soundpacks/:id" element={<CreateSoundPack viewMode={true} />} />
          <Route path="/soundpacks/:id/edit" element={<CreateSoundPack />} />
          <Route path="/purchased" element={<PurchasedSounds />} />
          <Route path="/uploaded" element={<Uploaded />} />
          <Route path="/likes" element={<Likes />} />
          <Route path="upload" element={<Upload />} />
          <Route path="sound/:soundId" element={<EditSound />} />
          <Route path="/me" element={<UserProfile />} />
          <Route path="/batch-upload" element={<BatchUpload />} />
          <Route path="/batch-edit" element={<BatchEdit />} />
          <Route path="/admin/tags" element={<TagsManager />} />
          <Route path="/admin/instruments" element={<InstrumentsManager />} />
          <Route path="/admin/genres" element={<GenresManager />} />
          <Route path="/:userId" element={<UserProfile />} />
          <Route path="/account" element={<AccountPage/>} />
          <Route path="/ai" element={<AIGenerator/>} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

function App() {
  return (
    <AudioNavigationProvider>
    <AuthProvider>
      <GoogleOAuthProvider clientId="400025157675-9qd00tfa32nc2opvolc1mg5sotecp241.apps.googleusercontent.com">
        <ToastProvider>
          <ToastInitializer>
            <AppRoutes />
          </ToastInitializer>
        </ToastProvider>
      </GoogleOAuthProvider>
    </AuthProvider>
    </AudioNavigationProvider>
  );
}

export default App;