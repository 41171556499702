import React, { useState } from 'react';
import axios from 'axios';
import { Menu } from 'lucide-react';

const AIGenerator = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    prompt: '',
    model: 'chirp-v4',
    styleNegative: '',
    continueAt: 10,
    style: '',
    audioUrl: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [generatedSamples, setGeneratedSamples] = useState(null);
  const [inProgressSamples, setInProgressSamples] = useState(null);
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const pollTaskStatus = async (taskId) => {
    try {
      const response = await axios.post('https://api.acedata.cloud/suno/tasks',
        {
          id: taskId,
          action: "retrieve"
        },
        {
          headers: {
            'Authorization': 'Bearer 641812e5c49949ebaad381cf2ac917f6',
            'Content-Type': 'application/json'
          }
        }
      );

      const samples = response.data.response.data;
      const allCompleted = samples.every(sample => sample.state === 'succeeded');

      if (allCompleted) {
        setGeneratedSamples(samples);
        setInProgressSamples(null);
        setIsLoading(false);
      } else {
        setInProgressSamples(samples);
        setTimeout(() => pollTaskStatus(taskId), 10000);
      }
    } catch (err) {
      setError('Failed to poll task status');
      setIsLoading(false);
    }
  };

  const handleGenerate = async () => {
    if (!formData.audioUrl) {
      setError('Please provide an audio URL');
      return;
    }

    setIsLoading(true);
    setError('');
    setGeneratedSamples(null);
    setInProgressSamples(null);
    
    try {
      const encodedUrl = encodeURIComponent(formData.audioUrl);
      const uploadResponse = await axios.post('https://api.acedata.cloud/suno/upload', 
        { audio_url: encodedUrl },
        { 
          headers: { 
            'Authorization': 'Bearer 641812e5c49949ebaad381cf2ac917f6',
            'Content-Type': 'application/json'
          }
        }
      );

      const { audio_id } = uploadResponse.data.data;

      const generateResponse = await axios.post('https://api.acedata.cloud/suno/audios',
        {
          action: "extend",
          prompt: formData.prompt,
          model: formData.model,
          custom: true,
          instrumental: true,
          style_negative: formData.styleNegative,
          audio_id: audio_id,
          continue_at: parseInt(formData.continueAt),
          style: formData.style,
          callback_url: "https://"
        },
        {
          headers: {
            'Authorization': 'Bearer 641812e5c49949ebaad381cf2ac917f6',
            'Content-Type': 'application/json'
          }
        }
      );

      pollTaskStatus(generateResponse.data.task_id);

    } catch (err) {
      setError(err.response?.data?.message || 'Failed to generate samples');
      setIsLoading(false);
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-2 rounded-md hover:bg-gray-700"
      >
        <Menu className="w-6 h-6" />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-96 bg-gray-800 rounded-lg shadow-lg p-4 z-50">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Audio URL
              </label>
              <input
                type="text"
                name="audioUrl"
                value={formData.audioUrl}
                onChange={handleInputChange}
                className="w-full px-4 h-12 rounded-md bg-gray-700 border border-gray-600 text-white"
                placeholder="Enter audio URL"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Style
              </label>
              <input
                type="text"
                name="style"
                value={formData.style}
                onChange={handleInputChange}
                className="w-full px-4 h-12 rounded-md bg-gray-700 border border-gray-600 text-white"
                placeholder="e.g. trap, melodic, 808"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Style Negative
              </label>
              <input
                type="text"
                name="styleNegative"
                value={formData.styleNegative}
                onChange={handleInputChange}
                className="w-full px-4 h-12 rounded-md bg-gray-700 border border-gray-600 text-white"
                placeholder="Elements to avoid..."
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Continue At (seconds)
              </label>
              <input
                type="number"
                step="0.001"
                name="continueAt"
                value={formData.continueAt}
                onChange={handleInputChange}
                className="w-full px-4 h-12 rounded-md bg-gray-700 border border-gray-600 text-white"
                min="0"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Prompt
              </label>
              <textarea
                name="prompt"
                value={formData.prompt}
                onChange={handleInputChange}
                className="w-full px-4 py-2 h-24 rounded-md bg-gray-700 border border-gray-600 text-white resize-none"
                placeholder="Enter your prompt..."
              />
            </div>

            <button
              onClick={handleGenerate}
              disabled={isLoading}
              className="w-full h-12 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
                </div>
              ) : (
                'Generate'
              )}
            </button>

            {error && (
              <p className="text-red-500 text-sm">{error}</p>
            )}

            {inProgressSamples && (
              <div className="mt-4">
                <h3 className="text-lg font-medium text-white mb-2">Generating Samples...</h3>
                {inProgressSamples.map((sample, index) => (
                  <div key={sample.id} className="mb-2">
                    <audio controls className="w-full">
                      <source src={sample.audio_url} type="audio/mpeg" />
                    </audio>
                  </div>
                ))}
              </div>
            )}

            {generatedSamples && (
              <div className="mt-4">
                <h3 className="text-lg font-medium text-white mb-2">Generated Samples</h3>
                {generatedSamples.map((sample, index) => (
                  <div key={sample.id} className="mb-2">
                    <audio controls className="w-full">
                      <source src={sample.audio_url} type="audio/mpeg" />
                    </audio>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AIGenerator;