import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from '../api/APIConfig';
import { Analytics } from '../api/Analytics/AnalyticsService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState(localStorage.getItem('access_token'));
  const [wordpressToken, setWordpressToken] = useState(localStorage.getItem('wordpress_token'));

  const setAuthHeader = useCallback((token) => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, []);

  const logout = useCallback(() => {
    const currentUserId = user?.soundware_id;
  
    if (currentUserId) {
      Analytics.trackSignOut(currentUserId);
    }
    Analytics.clearUser();

    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('wordpress_token');
    localStorage.removeItem('user_id');
    localStorage.clear();
    setAuthHeader(null);
    setIsAuthenticated(false);
    setUser(null);
    setAuthToken(null);
  }, [setAuthHeader]);

  const refreshUserData = useCallback(async () => {
    const userId = localStorage.getItem('user_id');

    try {
      if (!userId) throw new Error('No user ID available');
      const response = await axios.get(`${API_ENDPOINTS.GET_USER_ME}`);
      setUser(response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to refresh user data:', error);
      logout();
      throw error;
      
    }
  }, [logout]);

  const checkAuth = useCallback(async () => {
    const token = localStorage.getItem('access_token');
    const userId = localStorage.getItem('user_id');
    if (token && userId) {
      try {
        setAuthHeader(token);
        const response = await axios.get(`${API_ENDPOINTS.GET_USER_ME}`);
        setUser(response.data);
        setIsAuthenticated(true);
      } catch (error) {
        console.error('Authentication check failed:', error);
          logout();
        
      }
    } else {
      logout();
    }
    setIsLoading(false);
  }, [setAuthHeader, logout]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const loginAndLoadUser = async (username, password, googletoken) => {
    setIsLoading(true);
    try {
      const loginResponse = await axios.post(API_ENDPOINTS.SIGN_IN + '?is_soundbank_user=true', { username, password, googletoken});
      const { soundware_id, tokens } = loginResponse.data;
      localStorage.setItem('access_token', tokens.access_token);
      localStorage.setItem('refresh_token', tokens.refresh_token);
      localStorage.setItem('wordpress_token', tokens.wordpress_token);
      localStorage.setItem('user_id', soundware_id);
      
      setAuthToken(tokens.access_token);
      setWordpressToken(tokens.wordpress_token);
      setAuthHeader(tokens.access_token);
      
      // Track user in all analytics services
   // Track the user login
   Analytics.setUser({
    userId: soundware_id,
    userEmail: loginResponse.data.email,
    username: loginResponse.data.username
  });
  
  Analytics.trackSignIn(
    googletoken ? 'google' : 'email',
    {
      userId: soundware_id,
      userEmail: loginResponse.data.email,
      username: loginResponse.data.username
    }
  );

      setUser(loginResponse.data);
      setIsAuthenticated(true);
      return true;
    } catch (error) {
      console.error('Login failed:', error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const signupAndLoadUser = async (username, password, email) => {
    setIsLoading(true);
    try {
      // First, register the user
      const signupResponse = await axios.post(API_ENDPOINTS.SIGN_UP + '?is_soundbank_user=true', {
        username,
        password,
        email
      });

      const { soundware_id, tokens } = signupResponse.data;
      
      // Store the tokens and user ID
      localStorage.setItem('access_token', tokens.access_token);
      localStorage.setItem('wordpress_token', tokens.wordpress_token);
      localStorage.setItem('refresh_token', tokens.refresh_token);
      localStorage.setItem('user_id', soundware_id);
      
      // Set up authentication state
      setAuthToken(tokens.access_token);
      setWordpressToken(tokens.wordpress_token);
      setAuthHeader(tokens.access_token);
      setUser(signupResponse.data);
      setIsAuthenticated(true);
      
      return true;
    } catch (error) {
      console.error('Signup failed:', error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, 
      isLoading, 
      user, 
      authToken, 
      checkAuth, 
      loginAndLoadUser,
      signupAndLoadUser, // Added the new signup function to the context
      logout, 
      refreshUserData
    }}>
      {children}
    </AuthContext.Provider>
  );
};