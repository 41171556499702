import React from 'react';
import { motion } from 'framer-motion';
import { STEPS } from './UploadUtils';

const UploadProgressBar = ({ step, handleStepNavigation, isAnalyzing }) => {
    return (
        <div className="mb-12 relative">
            <div className="absolute inset-0 bg-gradient-to-r from-accent-end/10 to-accent-end/10 blur-xl -z-10" />
            <div className="relative">
                <div className="flex justify-between mb-4">
                    {Object.keys(STEPS).map((stepKey, index) => {
                        const isActive = index <= step;
                        const isCurrentStep = index === step;
                        const isAnalyzingStep = isAnalyzing && (index === STEPS.CLASSIFICATIONS || index === STEPS.METADATA);
                        const isClickable = handleStepNavigation.canAccessStep(index);
                        
                        return (
                            <div 
                                key={stepKey} 
                                className="flex flex-col items-center relative"
                                onClick={() => handleStepNavigation.goToStep(index)}
                                style={{ cursor: isClickable ? 'pointer' : 'not-allowed' }}
                            >
                                <motion.div
                                    initial={false}
                                    animate={{
                                        scale: isActive ? 1 : 0.8,
                                        backgroundColor: isActive ? 'var(--bg-secondary)' : 'var(--bg-primary)',
                                    }}
                                    whileHover={isClickable ? { scale: 1.1 } : {}}
                                    className={`w-10 h-10 rounded-full flex items-center justify-center
                                       
                                        ${isActive ? 'border-accent-end' : 'border-gray-600'}
                                        ${isAnalyzingStep ? 'animate-pulse' : ''}
                                        transition-all duration-300 ease-in-out
                                        ${isClickable ? 'hover:border-accent-end' : 'opacity-50'}`}
                                >
                                    {isAnalyzingStep ? (
                                        <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                                    ) : (
                                        <motion.div
                                            initial={false}
                                            animate={{ opacity: isActive ? 1 : 0.5 }}
                                            className={`text-sm ${isActive ? 'text-white' : 'text-gray-400'}`}
                                        >
                                            {index + 1}
                                        </motion.div>
                                    )}
                                </motion.div>

                                {index < Object.keys(STEPS).length - 1 && (
                                    <div className="absolute top-5 left-10 w-full h-[2px] -z-10">
                                        <div className="relative h-full">
                                            <div className="absolute inset-0 bg-gray-600" />
                                            <motion.div
                                                initial={false}
                                                animate={{
                                                    width: isActive ? "100%" : "0%",
                                                }}
                                                className="absolute inset-0 bg-gradient-to-r from-accent-end to-accent-end"
                                                transition={{ duration: 0.4, ease: "easeInOut" }}
                                            />
                                        </div>
                                    </div>
                                )}

                                <span className={`mt-3 text-sm font-medium transition-colors duration-300
                                    ${isActive ? 'text-white' : 'text-gray-400'}
                                    ${isClickable ? 'hover:text-accent-end' : 'opacity-50'}`}>
                                    {stepKey.replace('_', ' ')}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default UploadProgressBar;