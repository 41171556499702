// SoundPackCard Component
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Loader2 } from 'lucide-react';
import { fetchSoundpacks, deleteSoundpackById, updateSoundpack } from '../api/APIManager';
import { DEFAULT_SOUND_IMAGE } from '../api/APIConfig';
import { AuthContext } from '../context/AuthContext';
import { PlayIcon, PauseIcon } from 'lucide-react';
import { useAudioNavigation } from '../context/AudioNavigationContext';
import { useOutletContext } from 'react-router-dom';
import { MoreVertical, Pencil, Trash2, Star } from 'lucide-react';

const SoundPackCard = ({ soundpack, onUpdate }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { user } = useContext(AuthContext);
  const menuRef = useRef(null);
  const { 
    currentPlayingSound, 
    setCurrentPlayingSound,
    isPlaying,
    setIsPlaying 
  } = useAudioNavigation();
  const { setCurrentPlayingSound: setGlobalCurrentPlayingSound, setIsPlaying: setGlobalIsPlaying } = useOutletContext();
  const navigate = useNavigate();

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handlePlay = (e) => {
    e.stopPropagation(); // Prevent navigation when clicking play
    if (currentPlayingSound && currentPlayingSound.id === soundpack.id) {
      setIsPlaying(!isPlaying);
      setGlobalIsPlaying(!isPlaying);
    } else {
      setGlobalCurrentPlayingSound({
        id: soundpack.id,
        audio_preview: soundpack.audio_preview,
        name: soundpack.name,
        image: soundpack.avatar
      });
      setCurrentPlayingSound({
        id: soundpack.id,
        audio_preview: soundpack.audio_preview,
        name: soundpack.name,
        image: soundpack.avatar
      });
      setIsPlaying(true);
      setGlobalIsPlaying(true);
    }
  };

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    navigate(`/soundpacks/${soundpack.id}/edit`);
    setShowMenu(false);
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this sound pack?')) {
      try {
        await deleteSoundpackById(soundpack.id);
        // Trigger refresh of the soundpacks list
        if (onUpdate) onUpdate();
      } catch (error) {
        console.error('Error deleting sound pack:', error);
      }
    }
    setShowMenu(false);
  };

  const handleFeatureToggle = async (e) => {
    e.stopPropagation();
    setIsUpdating(true);
    try {
      const updatedData = {
        ...soundpack,
        featured: !soundpack.featured
      };
      await updateSoundpack(soundpack.id, updatedData);
      if (onUpdate) onUpdate();
    } catch (error) {
      console.error('Error updating sound pack featured status:', error);
    } finally {
      setIsUpdating(false);
      setShowMenu(false);
    }
  };

  const isCurrentlyPlaying = currentPlayingSound?.id === soundpack.id && isPlaying;
  const isOwner = user?.id === soundpack?.owner_id;
  const isAdmin = user?.admin;

  if (!soundpack) return null;

  return (
    <div
      onClick={() => navigate(`/soundpacks/${soundpack.id}`)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="bg-bg-secondary rounded-lg shadow hover:shadow-lg transition-all duration-200 cursor-pointer overflow-hidden relative"
    >
      <div className="p-4">
        <div className="relative aspect-square mb-4 overflow-hidden rounded-lg group">
          <img
            src={soundpack.avatar || DEFAULT_SOUND_IMAGE}
            alt={soundpack.name}
            className={`w-full h-full object-cover transition-transform duration-200 ${
              isHovered ? 'scale-105' : 'scale-100'
            }`}
            onError={(e) => {
              e.target.src = DEFAULT_SOUND_IMAGE;
            }}
          />
          {soundpack.audio_preview && (
            <div
              className={`absolute inset-0 flex items-center justify-center bg-black/40 transition-opacity duration-200 ${
                isHovered ? 'opacity-100' : 'opacity-0'
              }`}
            >
              <button
                onClick={handlePlay}
                className="p-3 rounded-full hover:bg-white/20 transition-colors duration-200"
              >
                {isCurrentlyPlaying ? (
                  <PauseIcon className="w-8 h-8 text-white" />
                ) : (
                  <PlayIcon className="w-8 h-8 text-white" />
                )}
              </button>
            </div>
          )}
          {/* Featured Badge */}
          {soundpack.featured && (
            <div className="absolute top-2 left-2 bg-accent-end px-2 py-1 rounded-full text-xs text-white">
              Featured
            </div>
          )}
        </div>
        <div className="space-y-2">
          <h3 className="font-semibold truncate text-text-primary">{soundpack.name}</h3>
          <div className="flex justify-between items-center">
            <div className="text-sm text-text-secondary">
              {soundpack.sound_count || 0} sounds
            </div>
            {/* Menu Button - Show for owned soundpacks or admin */}
            {(isOwner || isAdmin) && (
              <div className="relative" ref={menuRef}>
                <button
                  onClick={handleMenuClick}
                  className="p-1 rounded-full hover:bg-white/20 transition-colors duration-200"
                >
                  <MoreVertical className="w-4 h-4 text-text-secondary" />
                </button>
                {/* Dropdown Menu */}
                {showMenu && (
                  <div className="absolute bottom-full right-0 mb-1 py-1 w-36 bg-bg-secondary rounded-md shadow-lg z-50">
                    {isOwner && (
                      <button
                        onClick={handleEdit}
                        className="w-full px-4 py-2 text-left text-sm hover:bg-white/10 text-text-primary flex items-center gap-2"
                      >
                        <Pencil className="w-4 h-4" />
                        Edit
                      </button>
                    )}
                    {isOwner && (
                      <button
                        onClick={handleDelete}
                        className="w-full px-4 py-2 text-left text-sm hover:bg-white/10 text-red-500 flex items-center gap-2"
                      >
                        <Trash2 className="w-4 h-4" />
                        Delete
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// SoundPacksPage Component
const SoundPacksPage = ({ mySoundPacks = false }) => {
  const navigate = useNavigate();
  const [soundpacks, setSoundpacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const pageSize = 24;
  const loadingRef = useRef(false);

  const fetchSoundpacksData = async (newPage) => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    setLoading(true);
    
    try {
      let response;
      if (mySoundPacks) {
        response = await fetchSoundpacks({ 
          owner_id: user?.id,
          page_number: newPage,
          page_size: pageSize 
        });
      } else {
        response = await fetchSoundpacks({ 
          page_number: newPage,
          page_size: pageSize 
        });
      }
      
      setSoundpacks(response.items || []);
      setTotalResults(response.total || 0);
      setTotalPages(Math.ceil((response.total || 0) / pageSize));
      setPage(newPage);
    } catch (error) {
      console.error('Error fetching sound packs:', error);
    } finally {
      setLoading(false);
      loadingRef.current = false;
    }
  };

  useEffect(() => {
    if (user?.id || !mySoundPacks) {
      fetchSoundpacksData(1);
    }
  }, [mySoundPacks, user?.id]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages && !loading) {
      fetchSoundpacksData(newPage);
    }
  };

  const handleDirectPageInput = (e) => {
    if (e.key === 'Enter') {
      const pageNumber = parseInt(e.target.value);
      if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
        handlePageChange(pageNumber);
      }
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    
    pageNumbers.push(1);
    
    let startPage = Math.max(2, page - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(totalPages - 1, startPage + maxVisiblePages - 1);
    
    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(2, endPage - maxVisiblePages + 2);
    }
    
    if (startPage > 2) {
      pageNumbers.push('...');
    }
    
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    
    if (endPage < totalPages - 1) {
      pageNumbers.push('...');
    }
    
    if (totalPages > 1) {
      pageNumbers.push(totalPages);
    }
    
    return pageNumbers;
  };

  const handleCreateClick = () => {
    navigate('/soundpacks/create');
  };

  if (loading && page === 1) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-bg-primary">
        <Loader2 className="animate-spin h-8 w-8 text-accent-start" />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 bg-bg-primary min-h-screen">
      {/* Header with Create Button */}
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center gap-2">
          <h1 className="text-2xl font-bold text-text-primary">Sound Packs</h1>
          {totalResults > 0 && (
            <span className="text-sm text-text-secondary">
              {totalResults.toLocaleString()} total
            </span>
          )}
        </div>
        {mySoundPacks && (
          <button 
            onClick={handleCreateClick}
            className="bg-gradient-to-r from-accent-end to-accent-end text-text-primary px-4 py-2 rounded-full flex items-center gap-2 hover:opacity-90"
          >
            <Plus className="w-5 h-5" />
            Create Sound Pack
          </button>
        )}
      </div>

      {/* Sound Packs Grid */}
      <div className="relative">
        {loading && (
          <div className="absolute inset-0 bg-black/50 backdrop-blur-sm flex justify-center items-center z-10">
            <Loader2 className="animate-spin h-8 w-8 text-accent-start" />
          </div>
        )}
        
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 gap-6">
          {soundpacks.map((soundpack) => (
            <SoundPackCard 
              key={soundpack.id} 
              soundpack={soundpack} 
              onUpdate={() => fetchSoundpacksData(page)}
            />
          ))}

          {soundpacks.length === 0 && !loading && (
            <div className="col-span-full text-center py-12 text-text-secondary">
              No sound packs found. {mySoundPacks ? "Create your first sound pack!" : ""}
            </div>
          )}
        </div>

        {/* Pagination Controls */}
        {soundpacks.length > 0 && (
          <div className="mt-4 flex flex-col sm:flex-row items-center justify-between gap-4 border-t border-text-primary/10 pt-4 px-4 sm:px-8 pb-4">
            <div className="flex items-center gap-2 text-sm">
              <span>Total: {totalResults} items</span>
              <span className="text-text-primary/50">|</span>
              <span>Page {page} of {totalPages}</span>
            </div>
            
            <div className="flex items-center gap-2 flex-wrap justify-center">
              <button
                onClick={() => handlePageChange(page - 1)}
                disabled={page === 1 || loading}
                className="px-3 py-1 rounded-lg border border-text-primary/15 disabled:opacity-50 hover:bg-accent-end/10"
              >
                Previous
              </button>
              
              <div className="flex items-center gap-1">
                {getPageNumbers().map((pageNum, index) => (
                  <React.Fragment key={index}>
                    {pageNum === '...' ? (
                      <span className="px-2">...</span>
                    ) : (
                      <button
                        onClick={() => handlePageChange(pageNum)}
                        disabled={loading}
                        className={`px-3 py-1 rounded-lg border ${
                          pageNum === page
                            ? 'bg-accent-end text-text-primary border-accent-end'
                            : 'border-text-primary/15 hover:bg-accent-end/10'
                        }`}
                      >
                        {pageNum}
                      </button>
                    )}
                  </React.Fragment>
                ))}
              </div>

              <button
                onClick={() => handlePageChange(page + 1)}
                disabled={page === totalPages || loading}
                className="px-3 py-1 rounded-lg border border-text-primary/15 disabled:opacity-50 hover:bg-accent-end/10"
              >
                Next
              </button>

              <input
                type="number"
                min="1"
                max={totalPages}
                className="w-16 px-2 py-1 rounded-lg border border-text-primary/15 bg-transparent text-center"
                placeholder={page.toString()}
                onKeyDown={handleDirectPageInput}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SoundPacksPage;