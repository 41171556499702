import React, { useState, useContext, useEffect, useRef } from 'react';
import { Outlet, Link } from 'react-router-dom';
import Header from './Header';
import { Navigation } from './Navigation';
import AudioPlayer from './AudioPlayer/AudioPlayer';
import AudioControls from './AudioPlayer/AudioControls';
import { JUCESend, isJUCE } from '../context/JUCE';
import { useAudioNavigation } from '../context/AudioNavigationContext';

const Layout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    currentPlayingSound,
    setCurrentPlayingSound,
    isPlaying,
    setIsPlaying,
    navigateToSound
  } = useAudioNavigation();
  const [audioPlayerHeight, setAudioPlayerHeight] = useState(0);
  const contentRef = useRef(null);
  
  useEffect(() => {
    setAudioPlayerHeight(currentPlayingSound || isPlaying ? 82 : 0);
  }, [currentPlayingSound, isPlaying]);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
    if (isPlaying) {
      JUCESend('pauseSound', '');
    } else {
      JUCESend('playSound', currentPlayingSound);
    }
  };

  const handleNext = () => {
    const nextSound = navigateToSound('next');
    if (nextSound) {
      setCurrentPlayingSound(nextSound);
      JUCESend('playSound', nextSound);
      setIsPlaying(true);
    }
  };

  const handlePrevious = () => {
    const previousSound = navigateToSound('previous');
    if (previousSound) {
      setCurrentPlayingSound(previousSound);
      JUCESend('playSound', previousSound);
      setIsPlaying(true);
    }
  };

  const audioControlsHeight = 56;
  const totalPlayerHeight = audioPlayerHeight + (currentPlayingSound && isJUCE() ? audioControlsHeight : 0);

  return (
    <div className="flex flex-col h-screen bg-bg-primary text-text-primary font-sans relative">
      <Header className="fixed top-0 left-0 right-0 h-16 z-30" onMenuToggle={() => setIsMenuOpen(!isMenuOpen)} />
      
      <div className="flex flex-1 pt-16 w-full">
        <div 
          className="absolute inset-0 top-16 md:top-20 flex transition-all duration-300 ease-in-out" 
          style={{ 
            bottom: `${totalPlayerHeight + 16}px`
          }}
        >
          <Navigation 
            isOpen={isMenuOpen} 
            onClose={() => setIsMenuOpen(false)} 
            className="h-full overflow-y-auto flex-shrink-0" 
          />
          
          <main className="flex-1 min-w-0">
            <div 
              ref={contentRef}
              className="h-full overflow-y-auto overscroll-contain w-full bg-bg-primary"
            >
              <div className="min-h-full pb-20">
                <Outlet context={{ setCurrentPlayingSound, setIsPlaying }} />
              </div>
            </div>
          </main>
        </div>
      </div>

      <div className="fixed left-0 right-0 bottom-0 z-40">
        <AudioPlayer
          sound={currentPlayingSound}
          isPlaying={isPlaying}
          onPlayPause={handlePlayPause}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />
        {currentPlayingSound && isJUCE() && (
          <AudioControls 
            className="bg-bg-secondary border-t border-black border-opacity-25"
            style={{ height: `${audioControlsHeight}px` }}
          />
        )}
      </div>
      
      <Link
        to="/upload"
        className="fixed right-6 bg-gradient-to-r from-accent-start to-accent-end rounded-full flex items-center justify-center shadow-lg hover:opacity-90 transition-opacity duration-200 sm:hidden z-40 w-14 h-14"
        style={{ bottom: `${totalPlayerHeight + 16}px` }}
        aria-label="Upload Sound"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="text-white"
        >
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
      </Link>
    </div>
  );
};

export default Layout;