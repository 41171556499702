import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight, Play } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { fetchFeaturedSounds, fetchSoundpacks, fetchUsers, fetchSoundpackById } from '../../api/APIManager';
import { DEFAULT_SOUND_IMAGE, DEFAULT_USER_IMAGE } from '../../api/APIConfig';
import { VerifiedBadge } from '../../assets/Icons';

const CustomSoundpacksRow = ({ onPlay, title = "Genres" }) => {
  const navigate = useNavigate();
  const [soundpacks, setSoundpacks] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isPanning, setIsPanning] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
 
  const soundpackIds = title === "Instruments" ? [
    '01949816-3175-7d64-bf30-e39f4f0ac1f6',
     '01949816-0b89-74e5-82d4-3cde36eb7e9e',
      '01949815-e61a-7298-9cc8-e356a4fe35ec',
       '01949815-b34d-7269-99e1-42182607994d',
        '01949814-d819-7f26-b7b5-c150834bb287',
         '01949814-b46f-74fa-a46d-a7e9d1b1a759',
          '01949813-b7e3-7b0d-a88c-7b8e242a4c6f',
          '01949819-bac8-7ee5-9fdf-3cf3a11f3c5b',
          '0194982d-a381-71ab-933a-af123f9cdfb8',
          '0194982d-3fc0-7e10-96f3-9a5d0c00dbec'
  ] : [
    '019482c3-a8c3-7c94-90ab-64e6ea5c4bed',
    '01948294-fa24-7023-990a-a0877e3bb5d2',
    '019482a1-34d0-710f-8139-5ff0c5853c27',
    '019482d5-d599-7eea-9bd5-ebae9f6006a3',
    '019482dc-0505-7f7b-b2a2-135e808096db',
    '019482ea-40da-7bfd-b3ac-10a441cd4d0d',
    '019482ef-bc69-7e51-bd97-6df082e21dd5',
    '019482f4-6077-741e-8b26-d9848ac6c8b2',
    '019482f8-71f7-79ef-88d1-4cf73817b142', 
    '019482fc-a8c2-7865-931c-b099f5575914'
  ];
 
  const getSoundpacksToShow = () => {
    if (typeof window === 'undefined') return 4;
    if (window.innerWidth >= 2080) return 10;
    if (window.innerWidth >= 1280) return 8;
    if (window.innerWidth >= 768) return 5;
    return 3;
  };
 
  const [soundpacksToShow, setSoundpacksToShow] = useState(getSoundpacksToShow());
 
  useEffect(() => {
    const handleResize = () => {
      setSoundpacksToShow(getSoundpacksToShow());
    };
 
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
  useEffect(() => {
    const fetchSoundpacks = async () => {
      try {
        setIsLoading(true);
        const fetchedSoundpacks = await Promise.all(
          soundpackIds.map(async (id) => {
            const soundPack = await fetchSoundpackById(id);
            return soundPack;
          })
        );
        setSoundpacks(fetchedSoundpacks);
      } catch (error) {
        console.error('Error fetching soundpacks:', error);
      } finally {
        setIsLoading(false);
      }
    };
 
    fetchSoundpacks();
  }, []);
 
  const handlePlayClick = useCallback((e, sound) => {
    e.stopPropagation();
    onPlay(sound);
  }, [onPlay]);
 
  const handlePanStart = () => {
    setIsDragging(true);
    setIsPanning(true);
  };
 
  const nextSlide = () => {
    const lastIndex = Math.max(0, soundpacks.length - soundpacksToShow);
    setCurrentIndex(prev => prev >= lastIndex ? 0 : prev + 1);
  };
 
  const prevSlide = () => {
    const lastIndex = Math.max(0, soundpacks.length - soundpacksToShow);
    setCurrentIndex(prev => prev <= 0 ? lastIndex : prev - 1);
  };
 
  if (isLoading) {
    return (
      <div className="w-full h-64 flex items-center justify-center">
        <div className="w-10 h-10 border-4 border-t-accent-end border-accent-end/30 rounded-full animate-spin" />
      </div>
    );
  }
 
  return (
    <div className="space-y-4">
      <div className="px-4 pt-6">
        <h2 className="text-2xl font-bold text-white tracking-tight">{title}</h2>
      </div>
      
      <div className="relative w-full">
        <div className="relative">
          {soundpacks.length > soundpacksToShow && (
            <button
              onClick={prevSlide}
              className="absolute left-4 top-1/2 -translate-y-1/2 z-10 bg-black/40 backdrop-blur-md text-white p-3 rounded-full hover:bg-black/60 transition-all duration-300"
            >
              <ChevronLeft className="w-5 h-5" />
            </button>
          )}
 
          <div className="w-full overflow-x-auto no-scrollbar">
            <motion.div
              onPanStart={handlePanStart}
              onPanEnd={(event, info) => {
                setIsDragging(false);
                const swipeThreshold = 50;
                if (Math.abs(info.offset.x) > swipeThreshold) {
                  info.offset.x > 0 ? prevSlide() : nextSlide();
                }
                setTimeout(() => setIsPanning(false), 100);
              }}
              className="flex gap-6 px-4"
              animate={{ x: `-${currentIndex * (100 / soundpacksToShow)}%` }}
              transition={{ duration: 0.5, type: "spring", stiffness: 150, damping: 20 }}
            >
              {soundpacks.map((soundpack) => (
                <div
                  key={soundpack.id}
                  style={{ width: `${100 / soundpacksToShow}%` }}
                  className="flex-none"
                >
                  <div 
                    onClick={() => !isPanning && navigate(`/soundpacks/${soundpack.id}`)}
                    className="group relative rounded-xl overflow-hidden cursor-pointer aspect-square transition-all duration-300 transform hover:-translate-y-1"
                  >
                    <img
                      src={soundpack.avatar || DEFAULT_SOUND_IMAGE}
                      alt={soundpack.name}
                      className="absolute inset-0 w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t  opacity-90 group-hover:opacity-100 transition-opacity duration-300" />
                    
                    {soundpack.audio_preview && (
                      <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-30">
                        <button
                          onClick={(e) => handlePlayClick(e, {
                            id: soundpack.id,
                            audio_preview: soundpack.audio_preview,
                            name: soundpack.name,
                            image: soundpack.avatar
                          })}
                          className="p-4 rounded-full bg-white/90 text-black hover:bg-accent-end hover:text-white transform hover:scale-110 transition-all duration-300 shadow-xl"
                        >
                          <Play className="w-6 h-6" />
                        </button>
                      </div>
                    )}
 
                  </div>
                </div>
              ))}
            </motion.div>
          </div>
 
          {soundpacks.length > soundpacksToShow && (
            <button
              onClick={nextSlide}
              className="absolute right-4 top-1/2 -translate-y-1/2 z-10 bg-black/40 backdrop-blur-md text-white p-3 rounded-full hover:bg-black/60 transition-all duration-300"
            >
              <ChevronRight className="w-5 h-5" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
 };

const FeaturedSoundsCarousel = ({ onPlay, onFeaturedLoaded }) => {
  const navigate = useNavigate();
  const [featuredUsers, setFeaturedUsers] = useState([]);
  const [featuredUsersIndex, setFeaturedUsersIndex] = useState(0);
  const [featuredSounds, setFeaturedSounds] = useState([]);
  const [topSoundpacks, setTopSoundpacks] = useState([]);
  const [hipHopSoundpacks, setHipHopSoundpacks] = useState([]);
  const [rockSoundpacks, setRockSoundpacks] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [soundpackIndex, setSoundpackIndex] = useState(0);
  const [hipHopIndex, setHipHopIndex] = useState(0);
  const [rockIndex, setRockIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isPanning, setIsPanning] = useState(false);
  const [pauseTimeout, setPauseTimeout] = useState(null);

  const getCardsToShow = () => {
    if (typeof window === 'undefined') return 2;
    if (window.innerWidth >= 2080) return 5;
    if (window.innerWidth >= 1280) return 4;
    if (window.innerWidth >= 768) return 2.5;
    return 1.5;
  };

  const getSoundpacksToShow = () => {
    if (typeof window === 'undefined') return 4;
    if (window.innerWidth >= 2080) return 10;
    if (window.innerWidth >= 1280) return 8;
    if (window.innerWidth >= 768) return 5;
    return 3.5;
  };

  const [cardsToShow, setCardsToShow] = useState(getCardsToShow());
  const [soundpacksToShow, setSoundpacksToShow] = useState(getSoundpacksToShow());

  useEffect(() => {
    const handleResize = () => {
      setCardsToShow(getCardsToShow());
      setSoundpacksToShow(getSoundpacksToShow());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const nextSlide = (section) => {
    switch (section) {
      case 'featured':
        const lastFeaturedIndex = Math.max(0, featuredSounds.length - cardsToShow);
        setCurrentIndex(prev => prev >= lastFeaturedIndex ? 0 : prev + 1);
        break;
      case 'top':
        const lastTopIndex = Math.max(0, topSoundpacks.length - soundpacksToShow);
        setSoundpackIndex(prev => prev >= lastTopIndex ? 0 : prev + 1);
        break;
      case 'hiphop':
        const lastHipHopIndex = Math.max(0, hipHopSoundpacks.length - soundpacksToShow);
        setHipHopIndex(prev => prev >= lastHipHopIndex ? 0 : prev + 1);
        break;
      case 'rock':
        const lastRockIndex = Math.max(0, rockSoundpacks.length - soundpacksToShow);
        setRockIndex(prev => prev >= lastRockIndex ? 0 : prev + 1);
        break;
      case 'featured-users':
        const lastFeaturedUsersIndex = Math.max(0, featuredUsers.length - soundpacksToShow);
        setFeaturedUsersIndex(prev => prev >= lastFeaturedUsersIndex ? 0 : prev + 1);
        break;
    }
  };

  const prevSlide = (section) => {
    switch (section) {
      case 'featured':
        const lastFeaturedIndex = Math.max(0, featuredSounds.length - cardsToShow);
        setCurrentIndex(prev => prev <= 0 ? lastFeaturedIndex : prev - 1);
        break;
      case 'top':
        const lastTopIndex = Math.max(0, topSoundpacks.length - soundpacksToShow);
        setSoundpackIndex(prev => prev <= 0 ? lastTopIndex : prev - 1);
        break;
      case 'hiphop':
        const lastHipHopIndex = Math.max(0, hipHopSoundpacks.length - soundpacksToShow);
        setHipHopIndex(prev => prev <= 0 ? lastHipHopIndex : prev - 1);
        break;
      case 'rock':
        const lastRockIndex = Math.max(0, rockSoundpacks.length - soundpacksToShow);
        setRockIndex(prev => prev <= 0 ? lastRockIndex : prev - 1);
        break;
      case 'featured-users':
        const lastFeaturedUsersIndex = Math.max(0, featuredUsers.length - soundpacksToShow);
        setFeaturedUsersIndex(prev => prev <= 0 ? lastFeaturedUsersIndex : prev - 1);
        break;
    }
  };

  const handleProfileClick = useCallback((e, ownerId) => {
    e.stopPropagation();
    navigate(`/${ownerId}`);
  }, [navigate]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        const [
          soundsResponse,
          topPacksResponse,
          hipHopPacksResponse,
          rockPacksResponse,
          featuredUsersResponse
        ] = await Promise.all([
          fetchFeaturedSounds(),
          fetchSoundpacks({ page_number: 1, page_size: 20, featured: true }),
          fetchSoundpacks({
            page_number: 1,
            page_size: 20,
            genre_ids: "018f68fa-6c03-7acd-a71e-4537bb198918"
          }),
          fetchSoundpacks({
            page_number: 1,
            page_size: 20,
            genre_ids: "018f89df-c139-7e9d-8bd9-2c1c566803f8"
          }),
          fetchUsers(1, 20, { featured: true })
        ]);

        if (soundsResponse?.items) setFeaturedSounds(soundsResponse.items);
        if (topPacksResponse?.items) setTopSoundpacks(topPacksResponse.items.slice(0, 20));
        if (hipHopPacksResponse?.items) setHipHopSoundpacks(hipHopPacksResponse.items);
        if (rockPacksResponse?.items) setRockSoundpacks(rockPacksResponse.items);
        if (featuredUsersResponse?.items) setFeaturedUsers(featuredUsersResponse.items);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load content');
      } finally {
        setIsLoading(false);
        onFeaturedLoaded?.();
      }
    };
    loadData();
  }, [onFeaturedLoaded]);

  const handlePlayClick = useCallback((e, sound) => {
    e.stopPropagation();
    if (pauseTimeout) clearTimeout(pauseTimeout);
    setIsPlaying(true);
    onPlay(sound);
    const timeout = setTimeout(() => {
      setIsPlaying(false);
    }, 10000);
    setPauseTimeout(timeout);
  }, [onPlay, pauseTimeout]);

  const handlePanStart = () => {
    setIsDragging(true);
    setIsPanning(true);
  };

  const handleClick = (e, sound) => {
    if (!isPanning) {
      handlePlayClick(e, sound);
    }
    setIsPanning(false);
  };

  const renderFeaturedUsersCarousel = () => (
    <div className="space-y-6">
      <div className="px-4">
        <h2 className="text-2xl font-bold text-white tracking-tight pt-6">Featured Artists</h2>
      </div>
      
      <div className="relative w-full">
        <div className="relative">
          {featuredUsers.length > soundpacksToShow && (
            <button
              onClick={() => prevSlide('featured-users')}
              className="absolute left-4 top-1/2 -translate-y-1/2 z-10 bg-black/40 backdrop-blur-md text-white p-3 rounded-full hover:bg-black/60 transition-all duration-300 shadow-lg hover:shadow-xl"
            >
              <ChevronLeft className="w-5 h-5" />
            </button>
          )}

          <div className="w-full overflow-x-auto no-scrollbar">
            <motion.div
              className="flex gap-6 px-4"
              animate={{ x: `-${featuredUsersIndex * (100 / soundpacksToShow)}%` }}
              transition={{ duration: 0.5, type: "spring", stiffness: 150, damping: 20 }}
            >
              {featuredUsers.map((user) => (
                <div
                  key={user.id}
                  style={{ width: `${100 / soundpacksToShow}%` }}
                  className="flex-none p-2"
                >
                  <div 
                    onClick={() => navigate(`/${user.username}`)}
                    className="group relative rounded-full shadow-2xl overflow-hidden cursor-pointer aspect-square bg-bg-primary  hover:shadow-2xl transition-all duration-300"
                  >
                    <img
                      src={user.avatar || DEFAULT_USER_IMAGE}
                      alt={user.username}
                      className="w-full h-full object-cover rounded-lg transition-transform duration-500 group-hover:scale-110"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  </div>

                  <div className="mt-4 text-center">
                    <h3 className="font-semibold text-white truncate text-sm group-hover:accent-accent-end transition-colors duration-300">
                      {user.username}{user.verified && <VerifiedBadge className="inline-block ml-1" />}
                    </h3>
                  </div>
                </div>
              ))}
            </motion.div>
          </div>

          {featuredUsers.length > soundpacksToShow && (
            <button
              onClick={() => nextSlide('featured-users')}
              className="absolute right-4 top-1/2 -translate-y-1/2 z-10 bg-black/40 backdrop-blur-md text-white p-3 rounded-full hover:bg-black/60 transition-all duration-300 shadow-lg hover:shadow-xl"
            >
              <ChevronRight className="w-5 h-5" />
            </button>
          )}
        </div>
      </div>
    </div>
  );

  const renderSoundpackCarousel = (soundpacks, currentIndex, section, title) => (
    <div className="space-y-6">
      <div className="px-4 pt-6">
        <h2 className="text-2xl font-bold text-white tracking-tight">{title}</h2>
      </div>
      
      <div className="relative w-full">
        <div className="relative">
          {soundpacks.length > soundpacksToShow && (
            <button
              onClick={() => prevSlide(section)}
              className="absolute left-4 top-1/2 -translate-y-1/2 z-10 bg-black/40 backdrop-blur-md text-white p-3 rounded-full hover:bg-black/60 transition-all duration-300 shadow-lg hover:shadow-xl"
            >
              <ChevronLeft className="w-5 h-5" />
            </button>
          )}

          <div className="w-full overflow-x-auto no-scrollbar">
            <motion.div
              className="flex gap-6 px-4"
              animate={{ x: `-${currentIndex * (100 / soundpacksToShow)}%` }}
              transition={{ duration: 0.5, type: "spring", stiffness: 150, damping: 20 }}
            >
              {soundpacks.map((soundpack) => (
                <div
                  key={soundpack.id}
                  style={{ width: `${100 / soundpacksToShow}%` }}
                  className="flex-none"
                >
                  <div 
                    onClick={() => navigate(`/soundpacks/${soundpack.id}`)}
                    className="group relative rounded-xl overflow-hidden cursor-pointer aspect-square bg-black/20 shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1"
                  >
                    <img
                      src={soundpack.avatar || DEFAULT_SOUND_IMAGE}
                      alt={soundpack.name}
                      className="absolute inset-0 w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/40 to-transparent opacity-90 group-hover:opacity-100 transition-opacity duration-300" />
                    
                    {soundpack.audio_preview && (
                      <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-30">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePlayClick(e, {
                              id: soundpack.id,
                              audio_preview: soundpack.audio_preview,
                              name: soundpack.name,
                              image: soundpack.avatar
                            });
                          }}
                          className="p-4 rounded-full bg-white/90 text-black hover:bg-accent-end hover:text-white transform hover:scale-110 transition-all duration-300 shadow-xl"
                        >
                          <Play className="w-6 h-6" />
                        </button>
                      </div>
                    )}

                    <div className="absolute inset-x-0 bottom-0 p-4 space-y-2 backdrop-blur-sm bg-black/20">
                      <h3 className="font-semibold text-white truncate text-sm transition-colors duration-300">
                        {soundpack.name}
                      </h3>
                      {soundpack.owner && (
                        <div className="flex items-center gap-2 group/owner">
                          <img
                            src={soundpack.owner.avatar || DEFAULT_USER_IMAGE}
                            alt={soundpack.owner.username}
                            className="w-5 h-5 rounded-full border-2 border-white/20 flex-shrink-0 transition-colors duration-300"
                          />
                          <span className="text-xs text-white/80 group-hover/owner:text-accent-end truncate max-w-[100px] transition-colors duration-300">
                            {soundpack.owner.username}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </motion.div>
          </div>

          {soundpacks.length > soundpacksToShow && (
            <button
              onClick={() => nextSlide(section)}
              className="absolute right-4 top-1/2 -translate-y-1/2 z-10 bg-black/40 backdrop-blur-md text-white p-3 rounded-full hover:bg-black/60 transition-all duration-300 shadow-lg hover:shadow-xl"
            >
              <ChevronRight className="w-5 h-5" />
            </button>
          )}
        </div>
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <div className="w-full space-y-8">
        <div className="w-full h-[300px] sm:h-[400px] flex items-center justify-center">
          <div className="w-10 h-10 border-4 border-t-accent-end border-accent-end/30 rounded-full animate-spin shadow-lg" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full space-y-8">
        <div className="w-full h-[300px] sm:h-[400px] flex items-center justify-center">
          <p className="text-lg text-gray-400 bg-black/20 px-6 py-3 rounded-lg backdrop-blur-md shadow-lg">
            {error}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full space-y- pb-8">
      <style jsx global>{`
        .no-scrollbar {
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .no-scrollbar::-webkit-scrollbar {
          display: none;
        }
      `}</style>

      {/* Featured Sounds Section */}
      <div className="space-y-6">
        <div className="relative w-full h-[280px] sm:h-[340px]">
          <div className="absolute inset-0">
            <div className="relative h-full w-full">
              {featuredSounds.length > cardsToShow && (
                <button
                  onClick={() => prevSlide('featured')}
                  className="absolute left-4 top-1/2 -translate-y-1/2 z-10 bg-black/40 backdrop-blur-md text-white p-3 rounded-full hover:bg-black/60 transition-all duration-300 shadow-lg hover:shadow-xl"
                >
                  <ChevronLeft className="w-5 h-5" />
                </button>
              )}
              
              <div className="w-full h-full overflow-x-auto no-scrollbar">
                <motion.div
                  onPanStart={handlePanStart}
                  onPanEnd={(event, info) => {
                    setIsDragging(false);
                    const swipeThreshold = 50;
                    if (Math.abs(info.offset.x) > swipeThreshold) {
                      info.offset.x > 0 ? prevSlide('featured') : nextSlide('featured');
                    }
                    setTimeout(() => setIsPanning(false), 100);
                  }}
                  className="flex gap-6 px-4 h-full"
                  animate={{ x: `-${currentIndex * (100 / cardsToShow)}%` }}
                  transition={{ duration: 0.5, type: "spring", stiffness: 150, damping: 20 }}
                >
                  {featuredSounds.map((sound) => (
                    <div
                      key={sound.id}
                      className="flex-none h-full md:w-[500px] w-[350px]"  // Fixed width for featured sound cards
                    >
                      <div 
                        className="group relative w-full h-full rounded-xl overflow-hidden cursor-pointer bg-black/20 shadow-2xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1"
                        onClick={(e) => handleClick(e, sound)}
                      >
                        <div className="absolute inset-0">
                          <img
                            src={sound.image || sound.image2 || DEFAULT_SOUND_IMAGE}
                            alt={sound.name}
                            className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                          />
                        </div>
                        <div className="absolute inset-0 bg-gradient-to-t from-black/100 via-black/60 to-transparent opacity-90 group-hover:opacity-100 transition-opacity duration-300" />

                        <div className="absolute inset-0 p-6 flex flex-col justify-end">
                          <div className="flex justify-between items-end">
                            <h3 className="text-xl font-bold text-white line-clamp-1 mr-4 transition-colors duration-300">
                              {sound.name}
                            </h3>
                            <button
                              onClick={(e) => handlePlayClick(e, sound)}
                              className="flex-shrink-0 inline-flex items-center p-3 rounded-full border border-white/50 hover:border-none text-white/50 hover:bg-accent-end hover:text-white transform hover:scale-110 transition-all duration-300 shadow-2xl"
                            >
                              <Play className="w-5 h-5" />
                            </button>
                          </div>
                          <div className="flex items-center space-x-3 mt-4">
                            <button
                              onClick={(e) => handleProfileClick(e, sound.owner?.username)}
                              className="flex-shrink-0 transform hover:scale-110 transition-transform duration-300"
                            >
                              <img
                                src={sound.owner?.avatar || DEFAULT_USER_IMAGE}
                                alt={sound.owner?.username}
                                className="w-8 h-8 rounded-full border-2 border-white/20 hover:border-accent-end transition-colors duration-300 shadow-md"
                              />
                            </button>
                            <button
                              onClick={(e) => handleProfileClick(e, sound.owner?.username)}
                              className="flex items-center group/owner"
                            >
                              <span className="text-sm font-medium text-white/90 group-hover/owner:text-accent-end truncate transition-colors duration-300">
                                {sound.owner?.username}
                              </span>
                              {sound.owner?.verified && <VerifiedBadge className="ml-1.5" />}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </motion.div>
              </div>

              {featuredSounds.length > cardsToShow && (
                <button
                  onClick={() => nextSlide('featured')}
                  className="absolute right-4 top-1/2 -translate-y-1/2 z-10 bg-black/40 backdrop-blur-md text-white p-3 rounded-full hover:bg-black/60 transition-all duration-300 shadow-lg hover:shadow-xl"
                >
                  <ChevronRight className="w-5 h-5" />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

            {/* Featured Users Section */}
      {featuredUsers.length > 0 && renderFeaturedUsersCarousel()}


      {/* Top Sound Packs Section */}
      {renderSoundpackCarousel(topSoundpacks, soundpackIndex, 'top', 'Featured Sound Packs')}

      <CustomSoundpacksRow onPlay={onPlay} />

      {/* Instruments Section */}
      <CustomSoundpacksRow onPlay={onPlay} title="Instruments" />
    </div>
  );
};

export default React.memo(FeaturedSoundsCarousel);