import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { CustomMultiSelect } from '../../components/CustomMultiSelect';
import { VALID_KEYS, VALID_STATUS, pageVariants } from './UploadUtils';
import { CustomDialog, StyledSelect } from '../../components/CustomComponents';
import { createSanitizedFile } from '../../utils/sanitizeFilename';

export const StepContainer = ({ children }) => (
    <motion.div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={pageVariants}
        className="bg-bg-secondary rounded-xl shadow-xl backdrop-blur-sm 
                  border border-white/10 p-8 relative overflow-hidden"
    >
        <div className="absolute top-0 left-0 w-full h-full bg-bg-seconday" />
        <div className="relative z-10">
            {children}
        </div>
    </motion.div>
);

export const NavigationButtons = ({ 
    onPrev, 
    onNext, 
    isLastStep, 
    isSubmit, 
    isUploading, 
    contentOwnership, 
    isValid,
    showBackButton = true
}) => (
    <div className="flex justify-between mt-6">
        {showBackButton && (
            <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                type="button"
                onClick={onPrev}
                className="px-6 h-9 border-2 border-text-primary text-text-primary 
                         rounded-full shadow-lg shadow-black/10 
                         hover:border-text-primary hover:text-text-primary
                         hover:shadow-black/20 transition-all duration-300
                         bg-transparent"
            >
                Back
            </motion.button>
        )}
        <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            type={isSubmit ? "submit" : "button"}
            onClick={onNext}
            disabled={isSubmit ? (isUploading || !contentOwnership) : !isValid}
            className={`px-6 h-9 bg-gradient-to-r from-accent-end to-accent-end 
                     text-white rounded-full shadow-lg shadow-black/20 
                     hover:shadow-black/40 transition-all duration-300
                     ${!showBackButton ? 'ml-auto' : ''}  
                     ${(!isValid || (isSubmit && (isUploading || !contentOwnership))) ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
            {isSubmit ? (isUploading ? 'Uploading...' : 'Upload Sound') : 'Next'}
        </motion.button>
    </div>
);

export const SoundFileStep = ({ fileInputRef, dropZoneRef, handleFileSelection, selectedFile }) => {
    const isValid = !!selectedFile;

    const clearFile = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        handleFileSelection(null);
    };

    return (
        <div 
            ref={dropZoneRef}
            onDragOver={(e) => {
                e.preventDefault();
                dropZoneRef.current?.classList.add('border-accent-end');
            }}
            onDragLeave={(e) => {
                e.preventDefault();
                dropZoneRef.current?.classList.remove('border-accent-end');
            }}
            onDrop={(e) => {
                e.preventDefault();
                dropZoneRef.current?.classList.remove('border-accent-end');
                const droppedFile = e.dataTransfer.files[0];
                if (droppedFile && droppedFile.type.includes('audio')) {
                    const sanitizedFile = createSanitizedFile(droppedFile);
                    handleFileSelection(sanitizedFile);
                }
            }}
            className="border-4 border-dashed border-white/10 rounded-lg p-12 text-center hover:border-white/50 
                     transition-all duration-300 ease-in-out transform hover:scale-[1.01] 
                     hover:shadow-lg hover:shadow-black/20"
        >
            <div className="space-y-4">
                <motion.div 
                    className="text-6xl mb-4"
                    animate={{ 
                        scale: [1, 1.1, 1],
                        rotate: [0, 5, -5, 0]
                    }}
                    transition={{ 
                        duration: 2,
                        repeat: Infinity,
                        repeatType: "reverse"
                    }}
                >
                    🎵
                </motion.div>
                
                {!selectedFile ? (
                    <>
                        <h2 className="text-2xl font-bold">Drop your sound file here <span className="text-red-500">*</span></h2>
                        <p className="text-text-secondary">or</p>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            onClick={() => fileInputRef.current?.click()}
                            className="px-6 py-2 bg-white
                                     text-black rounded-full transition-all duration-300 
                                     hover:shadow-lg hover:shadow-black/20"
                        >
                            Choose File
                        </motion.button>
                    </>
                ) : (
                    <motion.div 
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="space-y-4"
                    >
                        <div className="flex items-center justify-center space-x-2">
                            <span className="text-lg font-medium">Selected File:</span>
                            <span className="text-white text-lg font-bold">{selectedFile.name}</span>
                        </div>
                        
                        <div className="flex justify-center space-x-4">
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => fileInputRef.current?.click()}
                                className="px-6 py-2 bg-white 
                                         text-black rounded-full transition-all duration-300 
                                         hover:shadow-lg hover:shadow-black/20"
                            >
                                Change File
                            </motion.button>
                            
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={clearFile}
                                className="px-6 py-2 border-2 border-red-500 text-red-500 
                                         rounded-full transition-all duration-300 
                                         hover:bg-red-500 hover:text-white"
                            >
                                Clear
                            </motion.button>
                        </div>
                    </motion.div>
                )}
                
                <input
                    ref={fileInputRef}
                    type="file"
                    accept=".wav,audio/wav,audio/x-wav"
                    onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                            const sanitizedFile = createSanitizedFile(file);
                            handleFileSelection(sanitizedFile);
                        }
                    }}
                    className="hidden"
                    required
                />
                <p className="text-sm text-text-secondary">Supported formats: WAV</p>
            </div>
            
            {!isValid && (
                <p className="text-red-500 text-sm mt-2">Please select a sound file</p>
            )}
            
            <NavigationButtons 
                onNext={() => handleFileSelection(selectedFile)} 
                isValid={isValid} 
                showBackButton={false}
            />
        </div>
    );
};

export const ImageStep = ({
    imageInputRef,
    imagePreview,
    setImage,
    setImagePreview,
    onPrev,
    onNext,
    name,
    isGeneratingImage,
    onGenerateAiImage,
}) => {
    const isValid = !!imagePreview;

    const handleFileUpload = (file) => {
        if (file && file.type.startsWith('image/')) {
            const sanitizedFile = createSanitizedFile(file);

            if (imagePreview) {
                URL.revokeObjectURL(imagePreview);
            }
            setImage(sanitizedFile);
            setImagePreview(URL.createObjectURL(sanitizedFile));
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        handleFileUpload(file);
    };

    return (
        <div className="space-y-6">
            <div>
                <label className="block text-sm font-medium text-text-secondary mb-2">
                    Upload Image <span className="text-red-500">*</span>
                </label>
                <div
                    className="border-2 border-dashed border-gray-400 rounded-lg p-6 hover:border-white transition-colors duration-300"
                    onDrop={handleDrop}
                    onDragOver={(e) => e.preventDefault()}
                >
                    {!imagePreview ? (
                        <div className="flex flex-col items-center gap-4">
                            <p className="text-text-secondary text-sm">Drop image here</p>
                            <div className="flex justify-center items-center gap-4">
                                <motion.button
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    onClick={() => imageInputRef.current?.click()}
                                    className="px-6 py-2 bg-accent-end hover:bg-accent-end 
                                             text-white rounded-full shadow-lg transition-all duration-300"
                                >
                                    Choose File
                                </motion.button>
                                <input
                                    ref={imageInputRef}
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        const file = e.target.files?.[0];
                                        handleFileUpload(file);
                                    }}
                                    className="hidden"
                                    required
                                />
                                <span className="text-text-secondary">or</span>
                                <motion.button
                                    whileHover={{ scale: 1.02 }}
                                    whileTap={{ scale: 0.98 }}
                                    onClick={onGenerateAiImage}
                                    disabled={isGeneratingImage || !name}
                                    className={`px-6 py-2 bg-accent-end text-white rounded-full 
                                             shadow-lg shadow-accent-end/20 hover:bg-accent-end 
                                             transition-all duration-300 flex items-center justify-center 
                                             space-x-2 whitespace-nowrap
                                             ${(isGeneratingImage || !name) ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    {isGeneratingImage ? (
                                        <>
                                            <motion.div
                                                animate={{ rotate: 360 }}
                                                transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                                                className="w-5 h-5 border-2 border-white border-t-transparent rounded-full"
                                            />
                                            <span>Finding artwork...</span>
                                        </>
                                    ) : (
                                        <span>Generate</span>
                                    )}
                                </motion.button>
                            </div>
                        </div>
                    ) : (
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            className="relative group"
                        >
                            <img
                                src={imagePreview}
                                alt="Preview"
                                className="max-w-full h-auto max-h-48 rounded-lg shadow-lg mx-auto"
                            />
                            <div
                                className="absolute inset-0 bg-black bg-opacity-50 opacity-0 
                                         group-hover:opacity-100 transition-opacity duration-300 
                                         rounded-lg flex items-center justify-center"
                            >
                                <button
                                    onClick={() => {
                                        if (imagePreview) {
                                            URL.revokeObjectURL(imagePreview);
                                        }
                                        setImage(null);
                                        setImagePreview(null);
                                    }}
                                    className="text-white bg-red-500 hover:bg-red-600 px-4 py-2 
                                             rounded-full transition-colors duration-300"
                                >
                                    Remove Image
                                </button>
                            </div>
                        </motion.div>
                    )}
                </div>
            </div>

            {!isValid && <p className="text-red-500 text-sm">Please upload or generate an image</p>}

            <NavigationButtons onPrev={onPrev} onNext={onNext} isValid={isValid} />
        </div>
    );
};

export const MetadataStep = ({ 
    name, setName, description, setDescription, bpm, setBpm, 
    musicalKey, setMusicalKey, isAnalyzing, onPrev, onNext 
}) => {
    const isValid = name && bpm;

    return (
        <div className="space-y-6">
            {isAnalyzing && (
                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="bg-accent-end/10 p-4 rounded-lg border border-accent-end/20"
                >
                    <p className="text-sm text-accent-end flex items-center">
                        <motion.div
                            animate={{ rotate: 360 }}
                            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                            className="mr-2"
                        >
                            ⚡
                        </motion.div>
                        Analysis in progress... Fields will be automatically populated when complete.
                    </p>
                </motion.div>
            )}

            <div className="space-y-4">
                <label className="block text-sm font-medium text-text-secondary mb-2">
                    Name <span className="text-red-500">*</span>
                </label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full rounded-md bg-bg-secondary border-white/20 border-2 shadow-sm 
                             focus:border-accent-end focus:ring focus:ring-accent-end focus:ring-opacity-50 
                             h-12 px-4 transition-all duration-300"
                    required
                />
                {!name && <p className="text-red-500 text-sm">Name is required</p>}
            </div>

            <div className="space-y-4">
                <label className="block text-sm font-medium text-text-secondary mb-2">
                    Description
                </label>
                <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows="4"
                    className="w-full rounded-md bg-bg-secondary border-white/20 border-2 shadow-sm 
                             focus:border-accent-end focus:ring focus:ring-accent-end focus:ring-opacity-50 
                             px-4 py-2 transition-all duration-300"
                />
            </div>

            <div className="grid grid-cols-2 gap-4">
                <div>
                    <label className="block text-sm font-medium text-text-secondary mb-2">
                        BPM <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="number"
                        value={bpm}
                        onChange={(e) => setBpm(e.target.value)}
                        className="w-full rounded-md bg-bg-secondary border-white/20 border-2 shadow-sm 
                                 focus:border-accent-end focus:ring focus:ring-accent-end focus:ring-opacity-50 
                                 h-12 px-4 transition-all duration-300"
                        min="0"
                        required
                    />
                    {!bpm && <p className="text-red-500 text-sm">BPM is required</p>}
                </div>
                <div>
                    <StyledSelect
                        value={musicalKey || " "}
                        onChange={(e) => setMusicalKey(e.target.value)}
                        options={VALID_KEYS.map(key => ({
                            value: key,
                            label: key === ' ' ? 'None' : key
                        }))}
                        placeholder="Select a key"
                        label={<>Key</>}
                    />
                </div>
            </div>

            <NavigationButtons onPrev={onPrev} onNext={onNext} isValid={isValid} />
        </div>
    );
};

export const VisibilityStep = ({ 
    status, 
    setStatus, 
    selectedLicense, 
    setSelectedLicense, 
    costInCredits, 
    setCostInCredits, 
    licenses, 
    onPrev, 
    onNext 
}) => {
    const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
    const selectedLicenseData = licenses.find(license => license.id === selectedLicense);
    const isValid = !!status && (status !== 'active' || (status === 'active' && selectedLicense));

    const handleLicenseClick = (e) => {
        e.preventDefault();
        if (selectedLicense && selectedLicenseData?.url) {
            setIsLicenseModalOpen(true);
        }
    };

    return (
        <div className="space-y-6">
            <div className="space-y-4">
                <StyledSelect
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    options={VALID_STATUS}
                    placeholder="Select visibility"
                    required
                    label={<>Visibility <span className="text-red-500">*</span></>}
                    error={!status && "Visibility is required"}
                />
            </div>
            
            {status === 'active' && (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="space-y-4"
                >
                    <StyledSelect
                        value={selectedLicense}
                        onChange={(e) => setSelectedLicense(e.target.value)}
                        options={licenses}
                        placeholder="Select a license"
                        required
                        label={<>License <span className="text-red-500">*</span></>}
                        error={status === 'active' && !selectedLicense && "License is required for public sounds"}
                        className="flex-1"
                    />

                    {selectedLicense && selectedLicenseData?.url && (
                        <button
                            onClick={handleLicenseClick}
                            className="px-4 h-12 bg-accent-end text-white rounded-md hover:bg-accent-end 
                                     transition-colors duration-300 flex items-center justify-center"
                        >
                            View
                        </button>
                    )}

                    <div>
                        <label className="block text-sm font-medium text-text-secondary mb-2">
                            Price in Tokens (1 Token = $0.07)
                        </label>
                        <input
                            type="number"
                            value={costInCredits}
                            onChange={(e) => setCostInCredits(e.target.value)}
                            className="w-full rounded-md bg-bg-secondary border-white/20 border-2 shadow-sm 
                                     focus:border-accent-end focus:ring focus:ring-accent-end focus:ring-opacity-50 
                                     h-12 px-4 transition-all duration-300"
                            min="0"
                        />
                    </div>
                </motion.div>
            )}

            <CustomDialog 
                isOpen={isLicenseModalOpen} 
                onClose={() => setIsLicenseModalOpen(false)}
                title={`${selectedLicenseData?.name || 'License'} Agreement`}
            >
                <div className="space-y-4">
                    <div className="h-[calc(100vh-250px)] md:h-[600px] mb-4 bg-bg-primary rounded overflow-hidden">
                        <iframe
                            src={selectedLicenseData?.url}
                            className="w-full h-full border-0"
                            title="License Agreement"
                        />
                    </div>
                    <div className="flex justify-end">
                        <button
                            onClick={() => setIsLicenseModalOpen(false)}
                            className="px-4 py-2 rounded bg-accent-end text-white hover:bg-accent-end 
                                     transition-colors duration-200"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </CustomDialog>

            <NavigationButtons onPrev={onPrev} onNext={onNext} isValid={isValid} />
        </div>
    );
};

export const ClassificationsStep = ({
    soundTypes,
    typeId,
    setTypeId,
    availableGenres,
    selectedGenres,
    setSelectedGenres,
    availableInstruments,
    selectedInstruments,
    setSelectedInstruments,
    availableTags,
    selectedTags,
    setSelectedTags,
    onPrev,
    onNext
}) => {
    const isValid = !!typeId;

    return (
        <div className="space-y-6">
            <div className="space-y-6">
                <div>
                    <CustomMultiSelect
                        options={soundTypes}
                        value={typeId ? [soundTypes.find(type => type.id === typeId)] : []}
                        onChange={selected => setTypeId(selected.length > 0 ? selected[0].id : '')}
                        placeholder="Select a sound type..."
                        label={<>Sound Type <span className="text-red-500">*</span></>}
                        isMulti={false}
                        required={true}
                    />
                    {!typeId && <p className="text-red-500 text-sm mt-1">Sound type is required</p>}
                </div>

                <CustomMultiSelect
                    options={availableGenres}
                    value={selectedGenres}
                    onChange={setSelectedGenres}
                    placeholder="Select genres..."
                    label="Genres"
                    isMulti={true}
                />

                <CustomMultiSelect
                    options={availableInstruments}
                    value={selectedInstruments}
                    onChange={setSelectedInstruments}
                    placeholder="Select instruments..."
                    label="Instruments"
                    isMulti={true}
                />

                <CustomMultiSelect
                    options={availableTags}
                    value={selectedTags}
                    onChange={setSelectedTags}
                    placeholder="Select tags..."
                    label="Tags"
                    isMulti={true}
                />
            </div>

            <NavigationButtons onPrev={onPrev} onNext={onNext} isValid={isValid} />
        </div>
    );
};

export const ReviewItem = ({ label, value }) => (
    <p className="mb-1">
        <span className="text-text-secondary">{label}:</span>{' '}
        <span className="text-text-primary">{value}</span>
    </p>
);

export const ReviewStep = ({
    fileName,
    audioPreview,
    togglePlayPause,
    isLocalPlaying,
    imagePreview,
    name,
    description,
    bpm,
    musicalKey,
    status,
    costInCredits,
    soundTypes,
    typeId,
    selectedGenres,
    selectedInstruments,
    selectedTags,
    selectedLicense,
    licenses,
    contentOwnership,
    setContentOwnership,
    onPrev,
    onSubmit,
    isUploading
}) => {
    const requiredFieldsComplete = fileName && imagePreview && name && bpm && status && typeId;

    return (
        <div className="space-y-6">
            <h2 className="text-xl font-semibold bg-white 
                        bg-clip-text text-transparent">Review Your Upload</h2>

            {!requiredFieldsComplete && (
                <div className="bg-red-500/10 border border-red-500/20 rounded-lg p-4">
                    <p className="text-red-500 text-sm">Please complete all required fields before submitting</p>
                </div>
            )}
            
            <div className="grid grid-cols-2 gap-4 p-6 bg-bg-secondary/50 rounded-lg backdrop-blur-sm 
                        border border-white/10 shadow-lg">
                <div>
                    <h3 className="font-medium mb-2">Sound File <span className="text-red-500">*</span></h3>
                    <p className="text-sm text-text-secondary">{fileName}</p>
                    {audioPreview && (
                        <motion.button
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            onClick={togglePlayPause}
                            className="mt-2 px-4 py-2 bg-gradient-to-r from-accent-end to-accent-end 
                                     text-white rounded-full text-sm shadow-lg shadow-black/20 
                                     hover:shadow-black/40 transition-all duration-300"
                        >
                            {isLocalPlaying ? 'Pause' : 'Play'} Preview
                        </motion.button>
                    )}
                </div>
                
                <div>
                    <h3 className="font-medium mb-2">Image <span className="text-red-500">*</span></h3>
                    {imagePreview && (
                        <motion.img 
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            src={imagePreview} 
                            alt="Preview" 
                            className="w-24 h-24 object-cover rounded-lg shadow-lg"
                        />
                    )}
                </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <div>
                    <h3 className="font-medium mb-2">Details</h3>
                    <div className="space-y-1">
                        <ReviewItem label="Name" value={name || <span className="text-red-500">Required</span>} />
                        <ReviewItem label="BPM" value={bpm || <span className="text-red-500">Required</span>} />
                        <ReviewItem label="Key" value={musicalKey} />
                        <ReviewItem 
                            label="Visibility" 
                            value={status ? (status === 'active' ? 'Public' : 'Private') : <span className="text-red-500">Required</span>} 
                        />
                        {status === 'active' && (
                            <>
                                <ReviewItem label="Cost" value={`${costInCredits} credits`} />
                                <ReviewItem 
                                    label="License" 
                                    value={selectedLicense ? 
                                        licenses.find(l => l.id === selectedLicense)?.name : 
                                        <span className="text-red-500">Required for public sounds</span>
                                    }
                                />
                            </>
                        )}
                    </div>
                </div>

                <div>
                    <h3 className="font-medium mb-2">Classifications</h3>
                    <div className="space-y-1">
                        <ReviewItem 
                            label="Type" 
                            value={typeId ? 
                                soundTypes.find(type => type.id === typeId)?.name : 
                                <span className="text-red-500">Required</span>
                            }
                        />
                        <ReviewItem 
                            label="Genres" 
                            value={selectedGenres.length > 0 ? 
                                selectedGenres.map(g => g.name).join(', ') : 
                                'None selected'
                            }
                        />
                        <ReviewItem 
                            label="Instruments" 
                            value={selectedInstruments.length > 0 ? 
                                selectedInstruments.map(i => i.name).join(', ') : 
                                'None selected'
                            }
                        />
                        <ReviewItem 
                            label="Tags" 
                            value={selectedTags.length > 0 ? 
                                selectedTags.map(t => t.name).join(', ') : 
                                'None selected'
                            }
                        />
                    </div>
                </div>
            </div>

            <div className="p-6 bg-bg-secondary/50 rounded-lg backdrop-blur-sm border border-white/10 shadow-lg">
                <h3 className="font-medium mb-2">Description</h3>
                <p className="text-sm">{description || 'No description provided'}</p>
            </div>

            <div className="flex items-center space-x-2 p-4 bg-bg-secondary/30 rounded-lg">
                <input
                    type="checkbox"
                    id="contentOwnership"
                    checked={contentOwnership}
                    onChange={(e) => setContentOwnership(e.target.checked)}
                    className="h-4 w-4 text-accent-end focus:ring-accent-end border-gray-300 rounded 
                             transition-all duration-300"
                    required
                />
                <label htmlFor="contentOwnership" className="text-sm text-text-secondary">
                    I declare that I own or have rights to the sounds I'm uploading and that they do not infringe on third-party rights.
                </label>
            </div>

            <NavigationButtons 
                onPrev={onPrev} 
                onNext={onSubmit}
                isLastStep={true}
                isSubmit={true}
                isUploading={isUploading}
                contentOwnership={contentOwnership}
                isValid={requiredFieldsComplete}
            />
        </div>
    );
};